import React, { useState } from 'react';
import DashFooter from '../DashFooter';
import SurgeriesDash from './Surgeries/SurgeriesDash';

const Surgeries = () => {
  const [surgeriesDash, setSurgeriesDash] = useState(false);


  return (
    <div>
      {!surgeriesDash ?
        <div className='mt-[20rem] w-full text-center'>
          <p className='text-lite-black font-display-medium text-sm'>Disclaimer</p>
          <p className='text-lite-black font-display-regular text-[0.8rem] mt-4 px-8'>
            Comparative Analytics are presented for the sole purpose of providing awareness to physicians regarding how their colleagues are using specific surgeries to cure their patients.
          </p>
          <button className='bg-[#1B453E] text-base px-24 py-3 rounded-[16px] mt-10 text-white mb-5' onClick={() => setSurgeriesDash(true)}>Accept</button>
          <DashFooter />
        </div> : <SurgeriesDash />}
    </div>

  )
}

export default Surgeries
