import React, { useEffect, useState } from "react";
import SyncDate from "../../AllComponents/SyncDate";
import arrowNext from "../../../assets/img/npBookingWindowAssets/forwardArrow.svg";
import calendar from "../../../assets/img/npBookingWindowAssets/calendar.svg";
import ring from "../../../assets/img/npBookingWindowAssets/greenRing.svg";
import next from "../../../assets/img/npBookingWindowAssets/next.svg";
import nextGrey from "../../../assets/img/npBookingWindowAssets/nextGrey.svg";
import redArrow from "../../../assets/img/npBookingWindowAssets/redArrow.svg";
import greenArrow from "../../../assets/img/npBookingWindowAssets/greenArrow.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import close from "../../../assets/img/clinicalAssets/close.svg";
import DashFooter from "../DashFooter";

const Summary = () => {
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState();
  const [period, setPeriod] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [myEncounters, setMyEncounters] = useState([]);
  const [claimReworks, setClaimReworks] = useState([]);
  const [payerMix, setPayerMix] = useState([]);
  const [openNotes, setOpenNotes] = useState([]);
  const [nps, setNps] = useState({});
  const [googleRating, setGoogleRating] = useState([]);
  const [mips, setMips] = useState([]);

  useEffect(() => {
    const getSummaryData = async () => {
      const { data } = await axios.get('https://drkpeyes-api.ignithocloud.com/api/dashboard/operations/comparative-analytics/np-booking-window');
      setResponseData(data);
      setPeriod(data.result.summary.period3.period);
      setMonth(data.result.summary.period3.month);
      setDate(data.result.summary.period3.date);
      setMyEncounters(data.result.summary.period3.data.myEncounters.data);
      setClaimReworks(data.result.summary.period3.data.claimReworks.data);
      setPayerMix(data.result.summary.period3.data.payerMix.data);
      setOpenNotes(data.result.summary.period3.data.openNotes.data);
      setNps(data.result.summary.period3.data.nps);
      setGoogleRating(data.result.summary.period3.data.googleRating);
      setMips(data.result.summary.period3.data.mips);
    }
    getSummaryData();
  }, [])

  const handleMTDComparison = () => {
    setPeriod(responseData.result.summary.period1.period);
    setMonth(responseData.result.summary.period1.month);
    setDate(responseData.result.summary.period1.date);
    setMyEncounters(responseData.result.summary.period1.data.myEncounters.data);
    setClaimReworks(responseData.result.summary.period1.data.claimReworks.data);
    setPayerMix(responseData.result.summary.period1.data.payerMix.data);
    setOpenNotes(responseData.result.summary.period1.data.openNotes.data);
    setNps(responseData.result.summary.period1.data.nps);
    setGoogleRating(responseData.result.summary.period1.data.googleRating);
    setMips(responseData.result.summary.period1.data.mips);
    setModalOpen(false);
  }

  const handleQTDComparison = () => {
    setPeriod(responseData.result.summary.period2.period);
    setMonth(responseData.result.summary.period2.month);
    setDate(responseData.result.summary.period2.date);
    setMyEncounters(responseData.result.summary.period2.data.myEncounters.data);
    setClaimReworks(responseData.result.summary.period2.data.claimReworks.data);
    setPayerMix(responseData.result.summary.period2.data.payerMix.data);
    setOpenNotes(responseData.result.summary.period2.data.openNotes.data);
    setNps(responseData.result.summary.period2.data.nps);
    setGoogleRating(responseData.result.summary.period2.data.googleRating);
    setMips(responseData.result.summary.period2.data.mips);
    setModalOpen(false);
  }

  const handleYTDComparison = () => {
    setPeriod(responseData.result.summary.period3.period);
    setMonth(responseData.result.summary.period3.month);
    setDate(responseData.result.summary.period3.date);
    setMyEncounters(responseData.result.summary.period3.data.myEncounters.data);
    setClaimReworks(responseData.result.summary.period3.data.claimReworks.data);
    setPayerMix(responseData.result.summary.period3.data.payerMix.data);
    setOpenNotes(responseData.result.summary.period3.data.openNotes.data);
    setNps(responseData.result.summary.period3.data.nps);
    setGoogleRating(responseData.result.summary.period3.data.googleRating);
    setMips(responseData.result.summary.period3.data.mips);
    setModalOpen(false);
  }

  const handleLast12MonthsComparison = () => {
    setPeriod(responseData.result.summary.period4.period);
    setMonth(responseData.result.summary.period4.month);
    setDate(responseData.result.summary.period4.date);
    setMyEncounters(responseData.result.summary.period4.data.myEncounters.data);
    setClaimReworks(responseData.result.summary.period4.data.claimReworks.data);
    setPayerMix(responseData.result.summary.period4.data.payerMix.data);
    setOpenNotes(responseData.result.summary.period4.data.openNotes.data);
    setNps(responseData.result.summary.period4.data.nps);
    setGoogleRating(responseData.result.summary.period4.data.googleRating);
    setMips(responseData.result.summary.period4.data.mips);
    setModalOpen(false);
  }

  return (
    <div>
      <div>
        <div className="bg-white px-4 fixed w-full top-[10rem] pb-3 mt-3 z-20">
          <div className="flex justify-between items-center cursor-pointer" onClick={() => setModalOpen(true)}>
            <div className="flex items-center gap-3">
              <div>
                <img src={calendar} alt="calendar" />
              </div>
              <div className="flex flex-col">
                <p className="font-display-medium text-sm text-lite-black">{period}</p>
                <p className="font-display-medium text-[#989898] text-sm">
                  {month}
                </p>
              </div>
            </div>
            <div>
              <img src={arrowNext} alt="next" />
            </div>
          </div>
          <hr className="mt-1" />
          <div className="mt-3">
            <SyncDate date={date} superScript="th" icon={ring} />
          </div>
        </div>
        <div className="px-4 pb-4 z-10 absolute w-full overflow-y-scroll top-[15.2rem] bottom-[3.5rem] no-scrollbar" >

          {/* My Encounters */}
          <div className="mt-4 rounded-[13px] bg-gradient-to-r from-[#C4E5FE] to-[#98CFFB] p-3" onClick={() => navigate('/encounter-metrics')}>
            <div className="flex items-center justify-between">
              <p className="text-[#0082CC] text-sm font-display-medium">My Encounters</p>
              <img src={next} alt="next" className="mix-blend-overlay h-5 w-5" />
            </div>
            <table className="font-display-regular text-lite-black table-auto w-full text-center tracking-wide">
              <thead>
                <tr className="text-[0.625rem] border-dotted">
                  <th></th>
                  <th className="py-2">My Data</th>
                  <th className="py-2">Region</th>
                  <th className="py-2">Platform</th>
                </tr>
              </thead>
              <tbody className="text-[0.75rem]">
                {myEncounters.map((encounter, index) => (
                  <tr className="text-[0.75rem] border-t border-t-[#95c2df] border-dotted" key={index}>
                    <td className="py-2">{encounter.name}</td>
                    <td className="py-2">{encounter.myData}</td>
                    <td className="py-2">{encounter.region}</td>
                    <td className="py-2">{encounter.platform}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* claim Reworks and Payer Mix */}
          <div className="flex mt-4 gap-3 w-full">
            <div className="rounded-[13px] bg-gradient-to-b from-[#C2FC73] to-[#8DC63F] w-[35%] p-3" onClick={() => navigate("/claim-reworks")}>
              <div className="flex justify-between items-center">
                <p className="text-[#096531] font-display-medium text-sm">Claim Reworks</p>
                <img
                  src={next}
                  alt="next"
                  className="mix-blend-overlay h-5 w-5"
                />
              </div>
              <div className="flex flex-col bg-white rounded-[13px] p-3 mt-3 gap-2">
                {claimReworks.map((claim, index) => (
                  <div className={`flex flex-col text-center ${claim.name === 'My Data' ? 'border-b-2 border-[#EFEDED] pb-2' : ''}`} key={index}>
                    <p className="text-[#18B854] font-display-bold text-base">{claim.data}</p>
                    <p className="text-[0.62rem] text-lite-black font-display-regular">{claim.name}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="rounded-[13px] bg-gradient-to-b from-[#FBCCAB] to-[#FCE2B7] p-3 w-[65%]" onClick={() => navigate('/payer-mix')}>
              <div className="flex justify-between items-center">
                <p className="text-[#B97200] text-sm font-display-medium">Payer Mix</p>
                <img
                  src={next}
                  alt="next"
                  className="mix-blend-overlay h-5 w-5"
                />
              </div>
              <div className="bg-white rounded-[13px] mt-3 p-3">
                <table className="font-display-regular w-full">
                  <tbody>
                    {payerMix.map((pay, index) => (
                      <tr key={index}>
                        <td className="text-[0.75rem] text-lite-black py-1">{pay.name}</td>
                        <td className="text-end text-sm text-[#B97200]">{pay.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <hr className="mt-4" />

          {/* Open Notes */}
          <div
            className="mt-3 bg-gradient-to-r from-[#DDDCFF] to-[#A9A9FB] rounded-[13px]"
            onClick={() => navigate("/open-notes")}
          >
            <div className="flex p-3 items-center">
              <div className="flex w-[35%] justify-between items-center me-6">
                <p className="text-[#8300D8] text-sm font-display-medium">
                  Open <br />Notes
                </p>
                <img
                  src={next}
                  alt="next"
                  className="mix-blend-overlay h-5 w-5"
                />
              </div>
              <div className=" bg-white rounded-[13px] p-3 w-[65%]">
                <div className="grid grid-cols-2">
                  {openNotes.map((notes, index) => (
                    <div key={index} className={`flex flex-col items-center justify-center ${notes.name === 'My Peers Platform' ? 'border-s-2 border-[#EFEDED]' : ''}`}>
                      <p className="text-base text-[#8300D8] font-display-bold">
                        {notes.value}
                      </p>
                      <p className='text-[0.625rem] text-lite-black font-display-regular tracking-wide'>{notes.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* NPS, Google Rating and MIPS */}
          <div className="mt-4 bg-[#EFEDED] rounded-[13px] p-2">
            <div className="flex justify-between px-3 gap-2">
              <div>
                <div className="flex flex-col">
                  <p className="text-lite-black text-sm font-display-medium ms-3">NPS</p>
                  <div className="flex flex-col rounded-[13px] bg-white p-2 my-2 gap-2">
                    <div className="flex justify-center gap-1">
                      <img src={nps.currYear > nps.prevYear ? greenArrow : redArrow} alt="arrow" />
                      <p className="text-base text-[#18B854] font-display-medium">{nps.prevYear > nps.currYear ? ([(nps.prevYear - nps.currYear) / nps.currYear] * 100).toFixed(1) : ([(nps.currYear - nps.prevYear) / nps.prevYear] * 100).toFixed(1)}%</p>
                    </div>
                    <div className="flex gap-3">
                      <p>{nps.currYear}</p>
                      <p className="text-[0.75rem] text-[#888888]">Current Year</p>
                    </div>
                    <div className="flex gap-3">
                      <p>{nps.prevYear}</p>
                      <p className="text-[0.75rem] text-[#888888]">Previous Year</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-col">
                  <p className="text-lite-black text-sm font-display-medium ms-3">Google Rating</p>
                  <div className="flex flex-col bg-white rounded-[13px] p-2 my-2 gap-2">
                    <div className="flex justify-center gap-1">
                      <img src={googleRating.currYear > nps.prevYear ? greenArrow : redArrow} alt="arrow" />
                      <p className="text-base text-[#DF1616] font-display-medium">{googleRating.prevYear > googleRating.currYear ? ([(googleRating.prevYear - googleRating.currYear) / googleRating.currYear] * 100).toFixed(1) : ([(googleRating.currYear - googleRating.prevYear) / googleRating.prevYear] * 100).toFixed(1)}%</p>
                    </div>
                    <div className="flex gap-3">
                      <p>{googleRating.currYear}</p>
                      <p className="text-[0.75rem] text-[#888888]">Current Year</p>
                    </div>
                    <div className="flex gap-3">
                      <p>{googleRating.prevYear}</p>
                      <p className="text-[0.75rem] text-[#888888]">Previous Year</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-col">
                  <div className="flex justify-between">
                    <p className="text-lite-black text-sm font-display-medium ms-3">MIPS</p>
                    <img
                      src={nextGrey}
                      alt="next"
                      className="h-5 w-5 "
                    />
                  </div>
                  <div className=" bg-white rounded-[13px] p-2 my-2 ">
                    <table>
                      <tbody>
                        <tr>
                          <td className="text-sm font-display-regular">{mips.currentScore}</td>
                          <td className="text-[0.75rem] text-[#888888] text-end">Current Score</td>
                        </tr>
                        <tr>
                          <td className="text-sm font-display-regular">{mips.platformPercentile}<sup>th</sup></td>
                          <td className="text-[0.75rem] text-[#888888] text-end">Platform Percentile</td>
                        </tr>
                        <tr>
                          <td className="text-sm font-display-regular">{mips.regionalPercentile}<sup>th</sup></td>
                          <td className=" text-[0.75rem] text-[#888888] text-end">Regional Percentile</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen ?
        <div className={`fixed top-0 left-0 right-0 z-30 w-full h-screen ${modalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110 modal'}`}>
          <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
            <div>
              <div className='m-5 flex justify-end'>
                <img src={close} alt="close" onClick={() => setModalOpen(false)} />
              </div>
              <div className='flex flex-col justify-center items-center font-display-medium px-4'>
                <div className='flex items-center gap-4 mb-5'>
                  <div>
                    <img src={calendar} alt="calendar" className='h-[1.875rem] w-[1.875rem]' />
                  </div>
                  <div className='flex flex-col font-display-medium text-sm'>
                    <p className='text-lite-black'>{period}</p>
                    <p className='text-[#888888]'>{month}</p>
                  </div>
                </div>
                <button className={`${period === 'MTD' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleYTDComparison}>YTD</button>
                <button className={`${period === 'QTD' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleQTDComparison}>QTD</button>
                <button className={`${period === 'YTD' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleMTDComparison}>MTD</button>
                <button className={`${period === 'Last 12 Months' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-[3.125rem] w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleLast12MonthsComparison}>Last 12 Months</button>
              </div>
            </div>
          </div>
        </div> : null}
      <DashFooter />
    </div>

  );
};

export default Summary;
