import React, { useState } from 'react';
import backArr from "../../../../assets/img/encounterAssets/Backward arrow.svg";
import graph from "../../../../assets/img/referralAssets/ref-graph.svg";
import list from "../../../../assets/img/referralAssets/list.svg";
import DashFooter from '../../DashFooter';
import RectangleBtn from '../../../AllComponents/RectangleBtn';
import location from "../../../../assets/img/encounterAssets/location.svg";
import arrow from "../../../../assets/img/encounterAssets/greenRightArr.svg";
import sync from "../../../../assets/img/encounterAssets/greenRing.svg";
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Line } from 'recharts';
import CATable from '../../../AllComponents/CATable';


const ReferralTrends = () => {

  const [activeChart, setActiveChart] = useState(true);

  const activeChange = () => {
    setActiveChart(true)
  }

  const activeHide = () => {
    setActiveChart(false)
  }

  const data = [
    {
      name: 'Apr',
      MyData: -50,
      NpsPlatform: -45,
      NpsRegion: -60
    },
    {
      name: 'May',
      MyData: -25,
      NpsPlatform: -60,
      NpsRegion: -80
    },
    {
      name: 'Jun',
      MyData: 0,
      NpsPlatform: -70,
      NpsRegion: -75
    },
    {
      name: 'Jul',
      MyData: 25,
      NpsPlatform: -48,
      NpsRegion: -75
    },
    {
      name: 'Aug',
      MyData: 20,
      NpsPlatform: -23,
      NpsRegion: -69
    },
    {
      name: 'Sep',
      MyData: 15,
      NpsPlatform: -10,
      NpsRegion: -27
    },
    {
      name: 'Oct',
      MyData: 2,
      NpsPlatform: 2,
      NpsRegion: -10
    },
    {
      name: 'Nov',
      MyData: 20,
      NpsPlatform: 48,
      NpsRegion: 2
    },
    {
      name: 'Dec',
      MyData: 34,
      NpsPlatform: 52,
      NpsRegion: 25
    },
    {
      name: 'Jan',
      MyData: 50,
      NpsPlatform: 59,
      NpsRegion: 49
    },
    {
      name: 'Feb',
      MyData: 68,
      NpsPlatform: 48,
      NpsRegion: 38
    },
    {
      name: 'Mar',
      MyData: 75,
      NpsPlatform: 32,
      NpsRegion: 25
    },
  ];

  return (
    <div className='bg-[#1B453E] bg-cover h-screen fixed w-screen z-30'>
      {/*Title Profile */}
      <div className='flex justify-between pt-16 px-4'>
        <div className="backArrow" ><img src={backArr} alt="backarrow" /></div>
        <div className="title text-white font-display-light tracking-wide text-base"><h2>Referral Sources</h2></div>
        <div></div>
      </div>

      {/*Referral Trend and List */}

      <div className="flex justify-between px-4">
        <div className="col flex">
          <div className='en-data flex items-center gap-2'>
            <img src={graph} className='en-pro-img' alt='pro-img' />
            <p className='flex text-sm font-display-regular text-[#8DC63F]'>Referral Trends</p>
          </div>
        </div>
        <div className="col flex justify-end">
          <div className='flex items-center -me-4'>
            <p className='en-switch-name flex align-middle text-sm font-thin text-white'>Referral Listing</p>
            <img src={list} className='en-pro-img' alt='pro-img' />
          </div>
        </div>
      </div>

      {/*White Background */}

      <div className='bg-white h-[77vh] rounded-t-[30px] fixed'>
        <div className='flex gap-4 justify-center pt-5 px-4'>
          <RectangleBtn showColor={activeChart} showChart={activeChange} value='Summary' />
          <RectangleBtn showColor={!activeChart} showChart={activeHide} value='Trends' />
        </div>

        {/*Grid */}
        <div className=' bg-gradient-to-r from-[#00A146] to-[#096531] flex flex-col h-[6.62rem] rounded-xl mx-4 mt-5 px-3'>

          <div className='flex justify-between'>
            <div className='rounded-[10px] bg-[#096531] h-8 w-8 justify-center flex items-center mt-3'>
              <div className='flex'>
                <div> <img src={location} alt="location" className='h-5' /></div>
              </div>
            </div>

            <div><img src={arrow} alt="location" className='h-3 mt-5' /></div>
          </div>

          <div className='text-white font-display-light text-sm tracking-wide mt-2'>Silver Spring</div>

          <div className='text-white font-display-thin text-xxs tracking-wider mt-2'>400 E Wayne Ave, MD 20901, US</div>

        </div>

        <div className='flex px-4 mt-4'><img src={sync} alt="" className='w-2.2' />
          <p className='ml-1 text-[0.62rem]'>Jun 30</p><sub className='text-[0.50rem] mt-1'>th</sub>
        </div>

        {/*chart */}

        <div className='h-[25.5rem] absolute z-20 overflow-scroll mt-2'>
          <ResponsiveContainer width="100%" height="50%">

            <AreaChart
              width={200}
              height={60}
              data={data}
              margin={{
                top: 5,
                right: 20,
                left: -30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="0 0" />
              <XAxis dataKey="name" tick={{ fontSize: 9 }} />
              <YAxis tick={{ fontSize: 9 }} />
              <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
              <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
              <Area type="monotone" dataKey="pv" stackId="1" stroke="#fff" fill="#ccc" dot={true} />
              <Line dataKey="uv" stroke="#FF9300" dot={false} />
            </AreaChart>

          </ResponsiveContainer>


          {/*table */}

          <div className='mt-2 gap-5 flex flex-col'>
            <CATable />
            <CATable />
            <CATable />
            <CATable />
          </div>

          {/*Total Average table */}
          <div className='px-4 mt-5'>
            <table className="table-fixed w-full align-middle justify-between items-center px-5 bg-[#E1E1E1] rounded-lg border-collapse">
              <tbody>
                <tr className='font-display-regular text-xs'>
                  <td className='px-4 py-1.5  rounded-l-xl text-xs font-display-medium'>Total Avg.</td>
                  <td className='px-4 py-1.5  rounded-l-xl text-xs'>23</td>
                  <td className='px-4 py-1.5  rounded-l-xl text-xs'>13</td>
                  <td className='px-4 py-1.5  rounded-l-xl text-xs'>43</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


      </div>


      {/*Footer */}
      <DashFooter />
    </div>
  )
}

export default ReferralTrends
