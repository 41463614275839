import React from 'react';

const AnalyticsTable = (props) => {
  return (
    <div>
      {!props.avg ?
        <div className='mx-4'>
          {props.tableData.map((quarter, index) => (
            <div className='rounded-lg m-1 bg-[#F5F5F5] mb-4' key={index}>
              <table className='font-display-regular w-full text-[0.75rem] text-center text-lite-black table-fixed'>
                <tbody>
                  {quarter.data.map((obj, index) => (
                    <tr key={index}>
                      <td className='py-2'>{obj.month}</td>
                      {obj.totalRatings ?
                        <td className='py-2'>{obj.totalRatings}</td> : null}
                      <td className='py-2'>{obj.myData}</td>
                      {props.showRegionData ?
                        <td className='py-2'>{obj.region}</td> : ''}
                      <td className='py-2'>{obj.platform}</td>
                    </tr>
                  ))
                  }
                  <tr className="bg-[#E1E1E1]">
                    <td className='rounded-bl-lg py-2'>Avg</td>
                    {quarter.totalRatingsAvg ?
                      <td className='py-2'>{quarter.totalRatingsAvg}</td> : null}
                    <td className='py-2'>{quarter.myDataAvg}</td>
                    {props.showRegionData ?
                      <td className='py-2'>{quarter.regionAvg}</td> : ''}
                    <td className='rounded-br-lg py-2'>{quarter.platformAvg}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div> :
        <div className='mb-5 mx-4'>
          <div className='rounded-lg m-1 bg-[#E1E1E1]'>
            <table className="font-display-regular text-[0.75rem] text-lite-black w-full table-fixed" >
              <tbody>
                <tr className='text-center'>
                  <td className='py-2 font-display-medium'>Total Avg.</td>
                  {props.totalRatingsOverallAvg ?
                    <td className='py-2'>{props.totalRatingsOverallAvg}</td> : ''}
                  <td>{props.myDataOverallAvg}</td>
                  {props.showRegionData ?
                    <td className='py-2'>{props.regionOverallAvg}</td> : ''}
                  <td className='py-2'>{props.platformOverallAvg}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  )
}

export default AnalyticsTable
