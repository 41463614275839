import React from 'react';

const CATable = () => {
  
  return (
    <div className="px-4 pt-2">
      <table className="table-fixed w-full text-center bg-[#F5F5F5] rounded-lg border-collapse">
        <tbody>
          <tr className='font-display-regular text-[0.75rem]'>
            <td className='py-1.5 rounded-l-xl'>Mar '23</td>
            <td className='py-1.5'>75</td>
            <td className='py-1.5'>32</td>
            <td className='py-1.5 rounded-r-xl'>46</td>
          </tr>
          <tr className='font-display-regular text-xs'>
            <td className='py-1.5  rounded-l-xl'>Feb '23</td>
            <td className='py-1.5 '>75</td>
            <td className='py-1.5 '>32</td>
            <td className='py-1.5 rounded-r-xl'>46</td>
          </tr>
          <tr className='font-display-regular text-xs'>
            <td className='py-1.5  rounded-l-xl'>Jan '23</td>
            <td className='py-1.5'>75</td>
            <td className='py-1.5'>32</td>
            <td className='py-1.5 rounded-r-xl'>46</td>
          </tr>
          <tr className='bg-[#E1E1E1] font-display-regular text-xs'>
            <td className='py-1.5  rounded-l-xl rounded-t-none'>Avg.</td>
            <td className='py-1.5 '>75</td>
            <td className='py-1.5 '>32</td>
            <td className='py-1.5 rounded-r-xl rounded-t-none'>16</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CATable
