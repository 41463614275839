import { Routes, Route } from "react-router-dom";
import EncounterMetrics from "./components/Dashboard/Clinical/EncounterMetrics";
import Home from './components/Splash';
import Dashboard from './components/Dashboard/Dashboard';
import NpBookingWindow from './components/Dashboard/Operations/NpBookingWindow';
import Referral from "./components/Dashboard/Clinical/Referral/Referral";
import ClaimReworks from "./components/Dashboard/Operations/ClaimReworks";
import PayerMix from "./components/Dashboard/Operations/PayerMix";
import MipsDash from "./components/Dashboard/Clinical/MipsDash";
import DaysInClinic from "./components/Dashboard/Clinical/DaysInClinic";
import ChargesPayments from "./components/Dashboard/Clinical/ChargesPayments/ChargesPayments";
import Profile from "./components/Profile/Profile";
import OpenNotes from "./components/Dashboard/Clinical/OpenNotes";
import ReferralTrends from "./components/Dashboard/Clinical/Referral/ReferralTrends";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/mips' element={<MipsDash />} />
        <Route path='/google-star-trend' element={<MipsDash />} />
        <Route path='/nps-score-trend' element={<MipsDash />} />
        <Route path='/open-notes' element={<OpenNotes />} />
        <Route path='/surg-without-prior-auth' element={<NpBookingWindow />} />
        <Route path='/refer' element={<Referral />} />
        <Route path='/encounter-metrics' element={<EncounterMetrics />} />
        <Route path='/days-in-clinic' element={<DaysInClinic />} />
        <Route path='/np-booking-window' element={<NpBookingWindow />} />
        <Route path='/referral-trends' element={<ReferralTrends />} />
        <Route path='/claim-reworks' element={<ClaimReworks />} />
        <Route path='/payer-mix' element={<PayerMix />} />
        <Route path='/charges-payments' element={<ChargesPayments />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </div>
  );
}

export default App