import React, { useEffect, useState } from 'react';
import TitleProfile from '../../../AllComponents/TitleProfile';
import greenRing from "../../../../assets/img/encounterAssets/greenRing.svg";
import SyncDate from "../../../AllComponents/SyncDate";
import greenGraph from "../../../../assets/img/encounterAssets/greenGraph.svg";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DashFooter from '../../DashFooter';
import close from "../../../../assets/img/clinicalAssets/close.svg";
import location from "../../../../assets/img/encounterAssets/location.svg";
import next from "../../../../assets/img/encounterAssets/greenRightArr.svg";
import intersection from "../../../../assets/img/encounterAssets/intersection.svg";
import updownArr from "../../../../assets/img/clinicalAssets/whiteUpDownArr.svg";
import axios from "axios";
import Centers from '../../../AllComponents/Centers';
import ChargesTrends from './ChargesTrends';
import PaymentsTrends from './PaymentsTrends';
import Loader from '../../../AllComponents/Loader';

const ChargesPayments = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [charges, setCharges] = useState(true);
  const [payments, setPayments] = useState(false);
  const [comparison, setComparison] = useState("YTD Comparison");
  const [chartData, setChartData] = useState([]);
  const [responseData, setResponseData] = useState();
  const [regionName, setRegionName] = useState("");
  const [regionLocation, setRegionLocation] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [selectConsolidatedCenters, setSelectConsolidatedCenters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [selectCentersPageOpen, setSelectCentersPageOpen] = useState(false);
  const [chargesMonthlyTrendsOpen, setChargesMonthlyTrendsOpen] = useState(false);
  const [paymentsMonthlyTrendsOpen, setPaymentsMonthlyTrendsOpen] = useState(false);

  const colorsLight = ['#C7EAFF', '#FFE3BD', '#CBFFA9'];
  const colors = ['#34B5FE', '#FF9300', '#7CC84A'];

  useEffect(() => {
    const getChargesPaymentsData = async () => {
      setLoading(true);
      const { data } = await axios.get('http://localhost:8081/api/dashboard/clinical/charges-payments/getData');
      setResponseData(data);
      setRegionName(data.result.region[0].regionName);
      setRegionLocation(data.result.region[0].location);
      setChartData(data.result.region[0].charges.ytdData.chartData);
      setMonth(data.result.region[0].charges.ytdData.month);
      setDate(data.result.region[0].charges.ytdData.date);
      setLoading(false);
    }
    getChargesPaymentsData();
  }, [])

  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const lines = payload.value.split('\n');
    return (
      <g transform={`translate(${x},${y + 8})`}>
        {lines.map((line, index) => (
          <text key={index} x={0} y={index * 13} textAnchor="middle" fill="#989899" fontSize={10}>
            {line}
          </text>
        ))}
      </g>
    );
  };

  const changeComparison = () => {
    setModalOpen(false);
    const result = responseData.result.region.filter((obj) => obj.regionName === regionName);
    if (comparison === 'YTD Comparison') {
      setComparison("MTD Comparison");
      if (charges) {
        setMonth(result[0].charges.mtdData.month);
        setChartData(result[0].charges.mtdData.chartData);
      }
      if (payments) {
        setMonth(result[0].payments.mtdData.month);
        setChartData(result[0].payments.mtdData.chartData);
        setTableData(result[0].payments.mtdData.tableData);
      }
    }
    if (comparison === 'MTD Comparison') {
      setComparison('YTD Comparison');
      if (charges) {
        setMonth(result[0].charges.ytdData.month);
        setChartData(result[0].charges.ytdData.chartData);
      }
      if (payments) {
        setMonth(result[0].payments.ytdData.month);
        setChartData(result[0].payments.ytdData.chartData);
        setTableData(result[0].payments.ytdData.tableData);
      }
    }
  }

  const CustomizedLabel = (props) => {
    const { x, y, value, fill } = props;
    return (
      <g transform={`translate(${x - 8},${y - 28})`}>
        <path id="Union_17" data-name="Union 17" d="M14.9,22.892l-1.326-1.762A2.769,2.769,0,0,1,13.088,20H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H29a3,3,0,0,1,3,3V17a3,3,0,0,1-3,3H19.367a2.767,2.767,0,0,1-.488,1.13l-1.326,1.762a1.57,1.57,0,0,1-2.652,0Z" fill={fill} fillOpacity={0.3} />
        <text fill={fill} fontSize={12} textAnchor="middle" dy={14} dx={15} fontFamily='SF-Pro-Display-Regular'>
          {value}
        </text>
      </g>
    );
  }

  const handlePayments = () => {
    setPayments(true);
    setCharges(false);
    const result = responseData.result.region.filter((obj) => obj.regionName === regionName);
    if (comparison === 'YTD Comparison') {
      setChartData(result[0].payments.ytdData.chartData);
      setTableData(result[0].payments.ytdData.tableData);
    }
    if (comparison === 'MTD Comparison') {
      setChartData(result[0].payments.mtdData.chartData);
      setTableData(result[0].payments.mtdData.tableData);
    }
  }

  const handleCenterSelection = () => {
    setSelectCentersPageOpen(true);
    setSelectedCenters([])
  }

  const handleSelectingCenters = () => {
    const locationArr = [];
    if (selectedCenters.length > 0) {
      const myArr = [];
      const valueArr = [];
      const valueArr1 = [];
      const filteredArray = responseData.result.region.filter((obj1) => selectedCenters.some((obj2) => obj2 === obj1.centerName))
      if (charges) {
        if (comparison === 'YTD Comparison') {
          filteredArray.forEach((obj) => { myArr.push(obj.charges.ytdData.chartData); locationArr.push(obj.regionName) });
          filteredArray.forEach((obj) => { myArr.push(obj.charges.mtdData.chartData); locationArr.push(obj.regionName) });
        }
        if (comparison === 'MTD Comparison') {
          filteredArray.forEach((obj) => { myArr.push(obj.charges.mtdData.chartData); locationArr.push(obj.regionName) });
        }
      }
      if (payments) {
        if (comparison === 'YTD Comparison') {
          filteredArray.forEach((obj) => { myArr.push(obj.payments.ytdData.chartData); locationArr.push(obj.regionName) });
        }
        if (comparison === 'MTD Comparison') {
          filteredArray.forEach((obj) => { myArr.push(obj.payments.mtdData.chartData); locationArr.push(obj.regionName) });
        }
      }
      if (locationArr.length === 1) {
        filteredArray.forEach((obj) => { setRegionName(obj.regionName); setRegionLocation(obj.location) })
      }
      if (locationArr.length === 2) {
        setRegionLocation(`${locationArr[0]} & ${locationArr.length - 1} Other`);
        setRegionName(`${locationArr[0]}`);
      }
      if (locationArr.length > 2) {
        setRegionLocation(`${locationArr[0]} & ${locationArr.length - 1} Others`);
        setRegionName(`${locationArr[0]}`);
      }
      myArr.forEach((obj) => obj.forEach((obj1) => { if (obj1.name === 2022) { valueArr.push(obj1.value) } else { valueArr1.push(obj1.value) } }));
      const totalValue1 = valueArr.reduce((acc, val) => acc + val, 0);
      const totalValue2 = valueArr1.reduce((acc, val) => acc + val, 0);
      setChartData([
        {
          "name": 2022,
          "value": totalValue1
        },
        {
          "name": 2023,
          "value": totalValue2
        },
      ])
      setSelectCentersPageOpen(false);
      setSelectConsolidatedCenters(false);
    }
    else {
      setRegionName("All Centers");
      responseData.result.region.map((obj) => locationArr.push(obj.region));
      setRegionLocation(`${locationArr[0]} & ${locationArr.length - 1} Others`)
      setSelectConsolidatedCenters(true);
      setChartData(responseData.result.overallRegionData);
      setSelectCentersPageOpen(false);
    }
  }

  const handleCharges = () => {
    setCharges(true);
    setPayments(false);
    const result = responseData.result.region.filter((obj) => obj.regionName === regionName);
    setChartData(result[0].charges.ytdData.chartData);
  }

  const handleMonthlyTrends = () => {
    if (charges) {
      setChargesMonthlyTrendsOpen(true);
    }
    if (payments) {
      setPaymentsMonthlyTrendsOpen(true);
    }
  }

  // const renderLegend = (props) => {
  //   const { payload } = props;

  //   return (
  //     <ul>
  //       {
  //         payload.map((entry, index) => (
  //           <li key={`item-${index}`}>{entry.value}</li>
  //         ))
  //       }
  //     </ul>
  //   );
  // }

  return (
    <div>
      {!selectCentersPageOpen ?
        !chargesMonthlyTrendsOpen ?
          !paymentsMonthlyTrendsOpen ?
            <div className='bg-doc-bg h-screen fixed w-full'>
              <div><TitleProfile head="Charges & Payments" /></div>
              {/*charges and payment sub head content started */}
              <div className='bg-white rounded-t-[30px] overflow-y-scroll w-full absolute top-[9rem] bottom-[3.5rem] no-scrollbar'>
                {!loading ?
                  <div>
                    <div className='px-4'>
                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <button type="button" className={`${charges ? 'bg-greenrectbtn text-white cursor-auto pointer-events-none' : 'bg-greyrectbtn text-lite-black cursor-pointer'} font-display-regular py-2.5 text-sm rounded-lg tracking-wide`} onClick={handleCharges}  >
                          Charges
                        </button>
                        <button type="button" className={`${payments ? 'bg-greenrectbtn text-white cursor-auto pointer-events-none' : 'bg-greyrectbtn text-lite-black cursor-pointer'} font-display-regular py-2.5 text-sm rounded-lg tracking-wide`} onClick={handlePayments} >
                          Payments
                        </button>
                      </div>

                      {/*subgrids */}
                      <div className="grid grid-cols-2 gap-4 mt-3">
                        <div className="info-card1 rounded-[12px] p-3 tracking-wide">
                          <div className='flex justify-between mb-2 items-center'>
                            <div className="rounded-lg bg-[#096531] h-8 w-8 flex justify-center items-center">
                              <input type="image" src={location} alt="image" />
                            </div>
                            <div>
                              <input type="image" src={next} alt="image" />
                            </div>
                          </div>
                          <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' onClick={handleCenterSelection}>{regionName}</div>
                          <div className='text-white text-[0.625rem] mt-2 font-display-light tracking-wide'>{regionLocation}</div>
                        </div>
                        <div className="info-card2 rounded-[12px] p-3 tracking-wide">
                          <div className='flex justify-between mb-2 items-center'>
                            <div className="rounded-lg bg-[#4E8700] h-8 w-8 flex justify-center items-center">
                              <input type="image" src={intersection} alt="image" />
                            </div>
                            <div onClick={() => setModalOpen(true)}>
                              <input type="image" src={updownArr} alt="image" />
                            </div>
                          </div>

                          <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' onClick={() => setModalOpen(true)} >{comparison}</div>
                          <div className='flex justify-between items-end mt-2 text-[0.625rem] font-display-light text-white tracking-wide'>
                            <div>{month}</div>
                            <div>
                              *professional only
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*Date and  trend report*/}
                      <div className='mt-5 flex justify-between items-center'>
                        <div> <SyncDate date={date} superScript="th" icon={greenRing} /></div>
                        <div onClick={handleMonthlyTrends}><img src={greenGraph} alt="graph" /></div>
                      </div>

                      {/*Charges and payment Report Chart */}
                      <div className='h-56 mt-[1.875rem]'>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            width={500}
                            height={300}
                            data={chartData}
                            margin={{
                              top: 20,
                              right: 0,
                              left: -20,
                              bottom: 0,
                            }}
                          >
                            <CartesianGrid vertical={false} stroke='#EFEDED' />
                            <XAxis dataKey="name" interval={0} tick={charges ? { fontSize: 10, fill: "#989899", fontFamily: 'SF-Pro-Display-Regular' } : <CustomizedXAxisTick />} axisLine={false} tickLine={false} />
                            <YAxis tick={{ fontSize: 10, fill: "#989899", fontFamily: 'SF-Pro-Display-Regular' }} axisLine={false} tickLine={false} domain={[0, 'auto']} />
                            <Tooltip />
                            {/* {charges ? <Legend content={renderLegend}  /> : ''} */}
                            {charges ? <Bar dataKey="value" barSize={17} label={<CustomizedLabel />}>
                              {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                              ))}
                            </Bar> : null}
                            {payments ? <Bar dataKey="2022" barSize={17}  >
                              {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colorsLight[index % 20]} stroke={colors[index % 20]} />
                              ))}
                            </Bar> : null}
                            {payments ? <Bar dataKey="2023" barSize={17} label={<CustomizedLabel />}>
                              {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                              ))}
                            </Bar> : null}
                          </BarChart>
                        </ResponsiveContainer>

                        {charges ?
                          <div className='flex justify-center space-x-5 '>
                            {
                              chartData.map((data) => (
                                <div className='whitespace-nowrap text-[0.75rem] font-display-regular'><span className={`border-x-[4px] rounded-2xl ${data.name === 2022 ? 'border-x-[#FF9300]' : 'border-x-[#34B5FE]'} me-1 py-[3px]`}></span>{data.name} : $ {data.value}
                                </div>
                              ))
                            }
                          </div> : null}

                        {payments ?
                          <div>
                            <table className="px-4 w-full text-lite-black tracking-wide mt-2">
                              <thead className="border-b border-dashed border-collapse border border-x-0 border-t-0">
                                <tr className="font-display-medium text-[0.625rem]">
                                  <th className="py-2 text-center"></th>
                                  <th className="py-2"><span className='inline-flex items-center'><span className={`w-[4px] h-[10px] inline-block bg-[#D9D9D9] border border-[#888888] rounded-[4px] me-1`}></span>2022</span></th>
                                  <th className="py-2"><span className='inline-flex items-center'><span className={`w-[4px] h-[10px] inline-block bg-[#888888] border border-[#888888] rounded-[4px] me-1`}></span>2023</span></th>
                                </tr>
                              </thead>
                              <tbody className='border-dashed border-collapse border border-x-0 tracking-wide'>
                                {tableData.map((data) => (
                                  <tr className="border-b border-dashed border-collapse border border-x-0 font-display-medium">
                                    <td className="font-display-regular whitespace-nowrap text-[0.75rem] py-2"><span className={`border-x-[4px] border-y-[2px] rounded-[90px] ${data.name === 'My Data' ? 'border-x-[#34B5FE] border-y-[#34B5FE]' : ''} ${data.name === 'My Peers - Region' ? 'border-x-[#FF9300] border-y-[#FF9300]' : ''}  ${data.name === 'My Peers - Platform' ? 'border-x-[#7CC84A] border-y-[#7CC84A]' : ''} me-1`}></span>{data.name}</td>
                                    <td className="border-dashed border-collapse border whitespace-nowrap border-l text-center text-[0.75rem] font-display-medium px-7">${data.year1}</td>
                                    <td className="border-r-0 border-dashed border-collapse border whitespace-nowrap border-l text-center text-[0.75rem] px-7">${data.year2}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div> : null
                        }
                      </div>
                    </div>

                    {/* Popup Modal */}
                    {modalOpen ?
                      <div className={`fixed top-0 left-0 right-0 z-30 w-full h-screen ${modalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110 modal'}`}>
                        <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
                          <div className='p-5'>
                            <div className='mb-8'>
                              <img src={close} alt="close" onClick={() => setModalOpen(false)} className='float-right' />
                            </div>
                            <div className='flex flex-col justify-center items-center font-display-medium'>
                              <div><p className='text-[#1B453E] font-display-medium mb-5'>Select Option</p>
                              </div>
                              <button className={`${comparison === 'YTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'}  p-6 rounded-lg mb-4 w-full drop-shadow-[0_0_10px_#0000000D] text-sm}`} onClick={changeComparison}>YTD Comparison</button>
                              <button className={`${comparison === 'MTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-6 rounded-lg mb-8 w-full drop-shadow-[0_0_10px_#0000000D] text-sm}`} onClick={changeComparison}>MTD Comparison</button>
                            </div>
                          </div>
                        </div>
                      </div> : ''}
                  </div> :
                  <div className='pt-48'>
                    <Loader />
                  </div>}
              </div>
              {/*Footer */}
              <div>
                <DashFooter />
              </div>

            </div> : <PaymentsTrends setPaymentsMonthlyTrendsOpen={setPaymentsMonthlyTrendsOpen} /> : <ChargesTrends setChargesMonthlyTrendsOpen={setChargesMonthlyTrendsOpen} /> :
        <Centers setRegionName={setRegionName} setSelectCentersPageOpen={setSelectCentersPageOpen} setRegionLocation={setRegionLocation} selectedCenters={selectedCenters} setSelectedCenters={setSelectedCenters} setChartData={setChartData} responseData={responseData} selectConsolidatedCenters={selectConsolidatedCenters} setSelectConsolidatedCenters={setSelectConsolidatedCenters} handleSelectingCenters={handleSelectingCenters} />
      }
    </div>
  )
}

export default ChargesPayments
