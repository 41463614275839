import React, { useEffect, useState } from 'react'
import backArr from "./../../assets/img/encounterAssets/Backward arrow.svg";
import tick from "../../assets/img/clinicalAssets/tick.svg";
import tickActive from "../../assets/img/clinicalAssets/tickActiveGreen.svg";
import axios from "axios";
import Loader from './Loader';

const Centers = ({ selectedCenters, setSelectedCenters, setSelectCentersPageOpen, selectConsolidatedCenters, setSelectConsolidatedCenters, handleSelectingCenters }) => {
  const [loading, setLoading] = useState(false);
  const [allCentersName, setAllCentersName] = useState("");
  const [allCentersData, setAllCentersData] = useState([]);

  useEffect(() => {
    const getCentersData = async () => {
      setLoading(true);
      const { data } = await axios.get('http://localhost:8081/api/dashboard/clinical/centers/getData');
      setAllCentersData(data.result.centers);
      setLoading(false);
    }
    getCentersData();
    //eslint-disable-next-line
  }, [])

  const selectingAllCenters = () => {
    const allCenterNames = allCentersData.map((center) => (center.name));
    setAllCentersName(allCenterNames.join(", "));
    setSelectedCenters([]);
  }

  const handleSubmitCenterSelection = () => {
    handleSelectingCenters();
  }

  const handleCenterChange = (e) => {
    var updatedCentersList = [...selectedCenters];
    if (e.target.checked) {
      updatedCentersList = [...selectedCenters, e.target.value];
    } else {
      updatedCentersList.splice(selectedCenters.indexOf(e.target.value), 1)
    }
    setSelectedCenters(updatedCentersList);
  }

  console.log(selectedCenters);

  return (

    <div className="bg-[#1B453E] w-full h-screen top-0 left-0 fixed z-20">
      <div>
        <div className='px-4 flex flex-col pt-14'>
          <div className="flex justify-between">
            <div className="backArrow" onClick={() => setSelectCentersPageOpen(false)}><img src={backArr} alt="backarrow" /></div>
            <div className="title text-white text-base tracking-wide font-display-light" ><h2>Select Center</h2></div>
            <div></div>
          </div>

          <div className='my-4'>
            <div className='flex justify-center gap-3' onClick={() => { setSelectConsolidatedCenters(!selectConsolidatedCenters); selectingAllCenters() }}>
              {selectConsolidatedCenters ?
                <img src={tickActive} alt="tick-active" className='h-5 w-5' /> :
                <img src={tick} alt="tick" className='h-5 w-5' />}
              <div className='text-white text-sm tracking-wide font-display-light'>Consolidated data of all centers</div>
            </div>
          </div>
        </div>
      </div>

      {/*center content started */}
      <div className="bg-white rounded-t-[26px] overflow-y-scroll absolute top-[8.8rem] z-10 bottom-0 w-full tracking-wide">
        {!loading ?
          <div>
            {!selectConsolidatedCenters ?
              <div className='px-4 pt-2'>
                {allCentersData.map((center, index) => (
                  <div className='pt-4' key={index}>
                    <div className='flex justify-between items-center'>
                      <div className='flex flex-col'>
                        <p className='font-display-medium text-sm text-lite-black'>{center.name}</p>
                        <p className='text-[0.75rem] text-[#888888] font-display-regular '>Address : {center.location}</p>
                      </div>
                      <div className=''>
                        <label htmlFor={center.name} className='block relative cursor-pointer ps-[30px]'>
                          <input
                            type="checkbox" id={center.name}
                            value={center.name} onChange={handleCenterChange} className='hidden' />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <hr className='mt-4' />
                  </div>
                ))}
              </div>
              :
              <div className='px-4 pt-6 flex flex-col justify-center items-center'>
                <p className='text-lite-black text-sm font-display-regular'>All centers selected</p>
                <p className='text-[#989899] text-[0.75rem] font-display-regular text-center p-4'>{allCentersName}</p>
              </div>
            }
            <div className='flex justify-center absolute w-full bottom-10'>
              <button className='bg-[#1B453E] w-auto text-white px-[68px] py-2.5 rounded-[15px] font-display-regular tracking-wide' onClick={handleSubmitCenterSelection}>Continue</button>
            </div>
          </div>
          : <div className="pt-48">
            <Loader />
          </div>}
      </div>
    </div>
  )
}

export default Centers
