import React from 'react';


const MonthlyTrendsTable = (props) => {
  return (
    <div>
      {!props.avg ?
        <div className='mx-4'>
          {props.tableData.map((quarter, index) => (
            <div className='rounded-lg m-1 bg-[#F5F5F5] mb-4' key={index}>
              <table className='font-display-regular w-full text-[0.75rem] text-center text-lite-black table-fixed'>
                <tbody>
                  {quarter.data.map((obj, index) => (
                    <tr key={index}>
                      <td className='py-2'>{obj.month}</td>
                      <td className='py-2'>{obj.myData}</td>
                      <td className='py-2'>{obj.previousYear}</td>
                      <td className='py-2'>{obj.myData > obj.previousYear ? obj.myData - obj.previousYear : obj.previousYear - obj.myData}</td>
                    </tr>
                  ))
                  }
                  <tr className="bg-[#E1E1E1]">
                    <td className='rounded-bl-lg py-2'>Sub Total</td>
                    <td className='py-2'>{quarter.myDataTotal}</td>
                    <td className='py-2'>{quarter.previousYearTotal}</td>
                    <td className='rounded-br-lg py-2'>{quarter.myDataTotal > quarter.previousYearTotal ? quarter.myDataTotal - quarter.previousYearTotal : quarter.previousYearTotal - quarter.myDataTotal}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div> :
        <div className='mb-5 mx-4'>
          <div className='rounded-lg m-1 bg-[#E1E1E1]'>
            <table className="font-display-regular text-[0.75rem] text-lite-black w-full table-fixed" >
              <tbody>
                <tr className='text-center'>
                  <td className='py-2 font-display-medium'>Total</td>
                  <td>{props.myDataOverallTotal}</td>
                  <td className='py-2'>{props.previousYearOverallTotal}</td>
                  <td className='py-2'>{props.myDataOverallTotal > props.previousYearOverallTotal ? props.myDataOverallTotal - props.previousYearOverallTotal : props.previousYearOverallTotal - props.myDataOverallTotal}</td>                   </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  )
}

export default MonthlyTrendsTable
