import React, { useEffect, useState } from 'react';
import TitleProfile from "../../AllComponents/TitleProfile";
import SyncDate from '../../AllComponents/SyncDate';
import greenRing from "../../../assets/img/encounterAssets/greenRing.svg";
import location from "../../../assets/img/encounterAssets/location.svg";
import intersection from "../../../assets/img/encounterAssets/intersection.svg";
import DashFooter from '../DashFooter';
import Centers from '../../AllComponents/Centers';
import updownArr from "../../../assets/img/clinicalAssets/whiteUpDownArr.svg";
import next from "../../../assets/img/encounterAssets/greenRightArr.svg";
import axios from "axios";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import close from "../../../assets/img/clinicalAssets/close.svg";
import Loader from '../../AllComponents/Loader';

const OpenNotes = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectCentersPageOpen, setSelectCentersPageOpen] = useState(false);
  const [comparison, setComparison] = useState("DTD Comparison");
  const [chartData, setChartData] = useState([]);
  const [responseData, setResponseData] = useState();
  const [regionName, setRegionName] = useState("");
  const [regionLocation, setRegionLocation] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [selectConsolidatedCenters, setSelectConsolidatedCenters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const colors = ['#34B5FE', '#FF9300', '#7CC84A'];

  const handleCenterSelection = () => {
    setSelectCentersPageOpen(true);
    setSelectedCenters([])
  }

  useEffect(() => {
    const getOpenNotesData = async () => {
      setLoading(true);
      const { data } = await axios.get('http://localhost:8081/api/dashboard/clinical/open-notes/getData');
      setResponseData(data);
      setRegionName(data.result.region[0].regionName);
      setRegionLocation(data.result.region[0].location);
      setChartData(data.result.region[0].dtdData.chartData);
      setTableData(data.result.region[0].dtdData.tableData);
      setMonth(data.result.region[0].dtdData.period);
      setDate(data.result.region[0].dtdData.date);
      setLoading(false);
    }
    getOpenNotesData();
  }, []);

  // function CustomizedXAxisTick(props) {
  //   const { x, y, payload } = props;
  //   console.log(payload.value)
  //   if (payload.value === 'My Data') {
  //     return (
  //       <text x={x - 15} y={y + 5} fill="#989899" fontFamily='SF-Pro-Display-Medium' letterSpacing={0.2} fontSize={10}>
  //         {payload.value}
  //       </text>
  //     )
  //   }

  //   if (payload.value === 'My Peers -\n Region') {
  //     const lines = payload.value.split('\n');
  //     return (
  //       <g >
  //         {lines.map((line, index) => (
  //           <text key={index} x={x} y={y} fill="#989899" fontFamily='SF-Pro-Display-Medium' letterSpacing={0.2} fontSize={10}>
  //             {line}
  //           </text>
  //         ))}
  //       </g>
  //     )
  //   }

  //   if (payload.value === 'My Peers -\n Platform') {
  //     const lines = payload.value.split('\n');
  //     return (
  //       <g transform={`translate(${x},${y})`}>
  //         {lines.map((line, index) => (
  //           <text key={index} x={x} y={y} fill="#989899" fontFamily='SF-Pro-Display-Medium' letterSpacing={0.2} fontSize={10}>
  //             {line}
  //           </text>
  //         ))}
  //       </g>
  //     )
  //   }
  // }

  const CustomizedLabel = (props) => {
    const { x, y, value, fill } = props;
    return (
      <g transform={`translate(${x - 8},${y - 28})`}>
        <path id="Union_17" data-name="Union 17" d="M14.9,22.892l-1.326-1.762A2.769,2.769,0,0,1,13.088,20H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H29a3,3,0,0,1,3,3V17a3,3,0,0,1-3,3H19.367a2.767,2.767,0,0,1-.488,1.13l-1.326,1.762a1.57,1.57,0,0,1-2.652,0Z" fill={fill} fillOpacity={0.3} />
        <text fill={fill} fontSize={12} textAnchor="middle" dy={14} dx={15} fontFamily='SF-Pro-Display-Regular'>
          {value}
        </text>
      </g>
    );
  }

  const handleSelectingCenters = () => {
    const locationArr = [];

    if (selectedCenters.length > 0) {
      console.log(selectedCenters);
      const myArr = [];
      const valueArr = [];
      const valueArr1 = [];
      const valueArr2 = [];

      const filteredArray = responseData.result.region.filter((obj1) => selectedCenters.some((obj2) => obj2 === obj1.centerName))
      console.log(filteredArray);

      if (comparison === 'YTD Comparison') {
        filteredArray.forEach((obj) => { myArr.push(obj.ytdData.chartData); locationArr.push(obj.regionName) });
        filteredArray.forEach((obj) => { myArr.push(obj.mtdData.chartData); locationArr.push(obj.regionName) });
      }

      if (comparison === 'DTD Comparison') {
        filteredArray.forEach((obj) => {
          myArr.push(obj.dtdData.chartData);
          locationArr.push(obj.regionName);
        });
      }

      if (comparison === 'MTD Comparison') {
        filteredArray.forEach((obj) => {
          myArr.push(obj.mtdData.chartData);
          locationArr.push(obj.regionName);
        });
      }

      console.log(locationArr);
      setRegionName(`${locationArr[0]}`);

      if (locationArr.length === 1) {
        if (comparison === 'DTD Comparison') {
          filteredArray.forEach((obj) => { console.log(obj); setRegionLocation(obj.location); setChartData(obj.dtdData.chartData); setTableData(obj.dtdData.tableData) })
        }
        if (comparison === 'YTD Comparison') {
          filteredArray.forEach((obj) => { console.log(obj); setRegionLocation(obj.location); setChartData(obj.dtdData.chartData); setTableData(obj.dtdData.tableData) })
        }
        if (comparison === 'MTD Comparison') {
          filteredArray.forEach((obj) => { console.log(obj); setRegionLocation(obj.location); setChartData(obj.mtdData.chartData); setTableData(obj.mtdData.tableData) })
        }
      }

      if (locationArr.length === 2) {
        setRegionLocation(`${locationArr[0]} & ${locationArr.length - 1} Other`);
      }

      if (locationArr.length > 2) {
        setRegionLocation(`${locationArr[0]} & ${locationArr.length - 1} Others`);
      }

      if (locationArr.length >= 2) {
        myArr.forEach((obj) => obj.forEach((obj1) => { if (obj1.name === 'My Data') { valueArr.push(obj1.value) }; if (obj1.name === 'My Peers -\n Region') { valueArr1.push(obj1.value) }; if (obj1.name === 'My Peers -\n Platform') { valueArr2.push(obj1.value) } }));
        const totalValue1 = valueArr.reduce((acc, val) => acc + val, 0);
        const totalValue2 = valueArr1.reduce((acc, val) => acc + val, 0);
        const totalValue3 = valueArr2.reduce((acc, val) => acc + val, 0);

        setChartData([
          {
            "name": "My Data",
            "value": totalValue1
          },
          {
            "name": "My Peers -\n Region",
            "value": totalValue2
          },
          {
            "name": "My Peers -\n Platform",
            "value": totalValue3
          },
        ])
        setTableData([
          {
            "name": "My Data",
            "value": totalValue1
          },
          {
            "name": "My Peers - Region",
            "value": totalValue2
          },
          {
            "name": "My Peers - Platform",
            "value": totalValue3
          },
        ])
      }
      setSelectCentersPageOpen(false);
      setSelectConsolidatedCenters(false);
    }
    else {
      setRegionName("All Centers");
      responseData.result.region.map((obj) => locationArr.push(obj.regionName));
      setRegionLocation(`${locationArr[0]} & ${locationArr.length - 1} Others`)
      if (comparison === 'DTD Comparison') {
        setChartData(responseData.result.overallRegionData.dtdData.chartData);
        setTableData(responseData.result.overallRegionData.dtdData.tableData);
      }
      if (comparison === 'MTD Comparison') {
        setChartData(responseData.result.overallRegionData.mtdData.chartData);
        setTableData(responseData.result.overallRegionData.mtdData.tableData);
      }
      if (comparison === 'YTD Comparison') {
        setChartData(responseData.result.overallRegionData.ytdData.chartData);
        setTableData(responseData.result.overallRegionData.ytdData.tableData);
      }
      setSelectConsolidatedCenters(true);
      setSelectCentersPageOpen(false);
    }
  }

  const handleDtdComparison = () => {
    setComparison('DTD Comparison');
    const selectedRegion = responseData.result.region.filter((obj1) => regionName === obj1.regionName);
    setMonth(selectedRegion[0].dtdData.period);
    setDate(selectedRegion[0].dtdData.date);
    setChartData(selectedRegion[0].dtdData.chartData);
    setTableData(selectedRegion[0].dtdData.tableData);
    setModalOpen(false);
  }

  const handleMtdComparison = () => {
    setComparison('MTD Comparison');
    const selectedRegion = responseData.result.region.filter((obj1) => regionName === obj1.regionName);
    setMonth(selectedRegion[0].mtdData.period);
    setDate(selectedRegion[0].mtdData.date);
    setChartData(selectedRegion[0].mtdData.chartData);
    setTableData(selectedRegion[0].mtdData.tableData);
    setModalOpen(false);
  }

  const handleYtdComparison = () => {
    setComparison('YTD Comparison');
    const selectedRegion = responseData.result.region.filter((obj1) => regionName === obj1.regionName);
    setMonth(selectedRegion[0].ytdData.period);
    setDate(selectedRegion[0].ytdData.date);
    setChartData(selectedRegion[0].ytdData.chartData);
    setTableData(selectedRegion[0].ytdData.tableData);
    setModalOpen(false);
  }

  return (
    <div>
      {!selectCentersPageOpen ?
        <div className="bg-[#1B453E] w-full h-screen fixed z-20">
          <div>
            <TitleProfile head="Open Notes" />
          </div>
          {/*Open notes sub head content started */}
          <div className="bg-white rounded-t-[26px] h-screen overflow-scroll z-10">
            {!loading ?
              <div>
                <div className='px-4'>
                  <div className="grid grid-cols-2 gap-4 mt-3">
                    {/* Region Selection Card */}
                    <div className="info-card1 rounded-[12px] p-3 tracking-wide">
                      <div className='flex justify-between mb-2 items-center'>
                        <div className="rounded-lg bg-[#096531] h-8 w-8 flex justify-center items-center">
                          <input type="image" src={location} alt="image" />
                        </div>
                        <div>
                          <input type="image" src={next} alt="image" />
                        </div>
                      </div>
                      <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' onClick={handleCenterSelection}>{regionName}</div>
                      <div className='text-white text-[0.625rem] mt-2 font-display-light tracking-wide'>{regionLocation}</div>
                    </div>
                    {/* Period Selection Card */}
                    <div className="info-card2 rounded-[12px] p-3 tracking-wide">
                      <div className='flex justify-between mb-2 items-center'>
                        <div className="rounded-lg bg-[#4E8700] h-8 w-8 flex justify-center items-center">
                          <input type="image" src={intersection} alt="image" />
                        </div>
                        <div onClick={() => setModalOpen(true)}>
                          <input type="image" src={updownArr} alt="image" />
                        </div>
                      </div>

                      <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' onClick={() => setModalOpen(true)} >{comparison}</div>
                      <div className='text-white text-[0.625rem] mt-2'>{month}</div>
                    </div>
                  </div>

                  <div className="mt-[1.875rem]" >
                    <SyncDate date={date} superScript="th" icon={greenRing} />
                  </div>

                  {/*status bar starts */}
                  <div className='h-56 mt-8'>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                          top: 10,
                          right: 0,
                          left: -30,
                          bottom: 0,
                        }} >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="name" tick={{ fontSize: 10, fontFamily: 'SF-Pro-Display-Medium', fill: '#989899', letterSpacing: 0.2 }} axisLine={false} tickLine={false} interval={0} />
                        <YAxis tick={{ fontSize: 10 }} type='number' allowDataOverflow='false' axisLine={false} tickLine={false} domain={['auto', 'dataMax + 5']} />
                        <Tooltip wrapperStyle={{ width: 100, height: 100, backgroundColor: '#ccc' }} />
                        <Bar dataKey="value" fill="#8884d8" barSize={18} label={<CustomizedLabel />}>
                          {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>

                  {/* table section*/}
                  <div>
                    <div className="flex flex-col">
                      <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                        <div className="inline-block min-w-full py-1 sm:px-0 lg:px-8">
                          <div className="overflow-hidden">
                            <table className="min-w-full text-lite-black">
                              <thead className="border-b">
                                <tr className="w-full">
                                  <th scope="col" className="columns-12"></th>
                                  <th scope="col" className="columns px-6 py-2 text-center text-[0.625rem] font-display-regular">Today</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData.map((row, index) => (
                                  <tr className="border-b text-[0.75rem]" key={index}>
                                    <td className="whitespace-nowrap py-2 font-display-regular tracking-wide"><span className={`${row.name === 'My Data' ? "border-x-[4px] rounded-[90px] border-x-[#34B5FE] me-1" : ''} ${row.name === 'My Peers - Region' ? "border-x-[4px] rounded-[90px] border-x-[#FF9300] me-1" : ''} ${row.name === 'My Peers - Platform' ? "border-x-[4px] rounded-[90px] border-x-[#7CC84A] me-1" : ''}`}></span>{row.name}</td>
                                    <td className="whitespace-nowrap border-l text-center font-display-medium">{row.value}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Pop-up Modal */}
                {modalOpen ?
                  <div className={`fixed top-0 left-0 right-0 z-30 w-full h-screen ${modalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110 modal'}`}>
                    <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
                      <div className='p-5'>
                        <div className='mb-8'>
                          <img src={close} alt="close" onClick={() => setModalOpen(false)} className='float-right' />
                        </div>
                        <div className='flex flex-col justify-center items-center font-display-medium'>
                          <div><p className='text-[#1B453E] font-display-medium mb-5'>Select Comparison</p>
                          </div>
                          <button className={`${comparison === 'DTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-6 rounded-lg mb-4 w-full drop-shadow-[0_0_10px_#0000000D] text-[0.875rem]`} onClick={handleDtdComparison}>DTD Comparison</button>
                          <button className={`${comparison === 'MTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-6 rounded-lg mb-4 w-full drop-shadow-[0_0_10px_#0000000D] text-[0.875rem]`} onClick={handleMtdComparison}>MTD Comparison</button>
                          <button className={`${comparison === 'YTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'}  p-6 rounded-lg mb-8 w-full drop-shadow-[0_0_10px_#0000000D] text-[0.875rem]`} onClick={handleYtdComparison}>YTD Comparison</button>
                        </div>
                      </div>
                    </div>
                  </div> : ''}
              </div> :
              <div className='pt-56'>
                <Loader />
              </div>
            }
          </div>
          <div>
            <DashFooter />
          </div>
        </div> :
        <Centers setSelectCentersPageOpen={setSelectCentersPageOpen} setRegionName={setRegionName} setRegionLocation={setRegionLocation} selectedCenters={selectedCenters} setSelectedCenters={setSelectedCenters} setChartData={setChartData} responseData={responseData} selectConsolidatedCenters={selectConsolidatedCenters} setSelectConsolidatedCenters={setSelectConsolidatedCenters} handleSelectingCenters={handleSelectingCenters} />
      }
    </div>
  )
}

export default OpenNotes; 
