import React from 'react';
import close from "../../assets/img/clinicalAssets/close.svg";

const PopupModal = (props) => {

  return (
    <div className={`fixed top-0 left-0 right-0 z-30 w-full h-screen ${props.modalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110 modal'}`}>
      <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
        <div className='p-5'>
          <div className='mb-8'>
            <img src={close} alt="close" onClick={() => props.setModalOpen(false)} className='float-right' />
          </div>
          <div className='flex flex-col justify-center items-center font-display-medium'>
            <div><p className='text-[#1B453E] font-display-medium mb-5'>{props.head}</p>
            </div>
            <button className={`${props.active ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-6 rounded-lg mb-4 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={() => props.changeTab()}>{props.option1}</button>
            <button className={`${props.inActive ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-6 rounded-lg mb-8 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={() => props.changeTab()}>{props.option2}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupModal
