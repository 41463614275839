import React from 'react';
import DashFooter from '../Dashboard/DashFooter';
import logout from "../../assets/img/ProfileAssests/Logout.svg";
import help from "../../assets/img/ProfileAssests/Help Centre.svg";
import ProfilePic from "../../assets/img/ProfileAssests/Profile Picture.png";
import provider from "../../assets/img/ProfileAssests/Provider Icon.svg";
import Myself from "../../assets/img/ProfileAssests/Myself Icon.svg";
import otherProvider from "../../assets/img/ProfileAssests/Other Provider - Gray Icon.svg";
import locator from "../../assets/img/ProfileAssests/Location.svg";
import swap from "../../assets/img/ProfileAssests/Switch.svg";

const Profile = () => {
  return (
    <div>
      
      <div className='bg-[#DDE3E2] w-screen h-screen bg-cover z-30 fixed'>
        {/*sub-head */}
        <div className='bg-doc-bg w-screen h-[14.6rem] rounded-b-[1.8rem] px-4 tracking-wide'>
          
          <div className='flex justify-between pt-12'>
            <img src={logout} alt="logout" />
            <h5 className=' font-display-light text-white'>Profile</h5>
            <img src={help} alt="" />
          </div>

          <div className='flex gap-2 mt-11'>
            <div>
            <img src={ProfilePic} alt="profile" />
           </div>
            <div className='mt-6'>
            <h3 className='text-white font-display-light text-xl'>Dr. Doug Wisor</h3>
            <p className='text-[#8DC63F] font-display-light'>CEO</p>
           </div>
          </div>
        </div>

        <div className='flex justify-between bg-[#FFFFFF] mx-4 h-14 rounded-[10px]  items-center px-4 shadow-sm outline outline-2 outline-white mt-6'>
          <div><p className=' font-display-thin tracking-wider'>Viewing as</p></div>
          <div className='flex gap-2'>
            <img src={provider} alt="provider" />
            <p className='text-[#8DC63F] font-display-light tracking-wider mt-1'>Provider</p>
          </div>
        </div>

        {/*Profile Grid */}

        <div className='flex gap-5 justify-center mt-6'>
          <div className='bg-[#8DC63F] px-[3.3rem] py-10 rounded-xl justify-center space-y-5 outline outline-1 outline-white'>
            <img src={ Myself} alt="Myself"/>
           <p className='justify-center ml-2 font-display-light text-white tracking-wide'>Myself</p>
          </div>

          <div className='bg-[#E9E9E9] px-[2rem] py-10 rounded-xl justify-center space-y-5 outline outline-1 outline-white'>
            <img src={otherProvider} alt="Myself"/>
           <p className='justify-center font-display-regular text-[#888888] tracking-wide items-center'>Other Provider</p>
          </div>
        </div>

        
      {/*center section */}

      <div className='flex justify-between bg-[#FFFFFF] mx-4 h-14 rounded-[10px]  items-center px-4 shadow-sm outline outline-2 outline-white mt-6'>
          <div><p className=' font-display-thin tracking-wider'>Center</p></div>
          <div className='flex gap-2'>
            <img src={locator} alt="provider" />
            <p className='text-black font-display-semibold tracking-wide mt-1 text-base'>Brooklyn</p>
          </div>
        </div>

        <div className='py-5 bg-[#8DC63F] mx-4 rounded-lg outline outline-1 outline-white mt-6 flex justify-center gap-3'>
          <img src={swap} alt="sync" />
          <p className=' font-display-regular text-[#1B453E]'>Switch to Regional View</p>
        </div>

        <DashFooter profile={true}/>
      </div>

    </div>
  )
}

export default Profile
