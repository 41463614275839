import React, { useEffect, useState } from 'react';
import refresh from "../../../../assets/img/clinicalAssets/circleGrey.svg";
import info from "../../../../assets/img/clinicalAssets/infoGrey.svg";
import greenUpDownArr from "../../../../assets/img/clinicalAssets/greenUpDownArr.svg";
import { BarChart, Bar, Cell, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import axios from "axios";
import close from "../../../../assets/img/clinicalAssets/close.svg";

const MIPS = (props) => {
  const [month, setMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [date, setDate] = useState("");
  const [chartData, setChartData] = useState([]);
  const [mips, setMips] = useState(false);
  const [tin, setTin] = useState(false);
  const [tab, setTab] = useState("");
  const [mipsScoreImpact, setMipsScoreImpact] = useState(false);

  useEffect(() => {
    const getMipsData = async () => {
      const { data } = await axios.get('https://drkpeyes-api.ignithocloud.com/api/dashboard/clinical/mips/getData');
      setLoading(true);
      setResponseData(data);
      setMonth(data.result.month);
      setDate(data.result.date);
      setMips(true);
      setTab(data.result.mipsData.name);
      setChartData(data.result.mipsData.data);
      setLoading(false);
    }
    getMipsData();
  }, [])

  const colors = ['#34B5FE', '#FF9300', '#7CC84A'];

  const findMaximum = () => {
    const maxArr = chartData.map((obj) => obj.value);
    const maxVal = Math.max(...maxArr);
    return maxVal;
  }

  const findMinimum = () => {
    const minArr = chartData.map((obj) => obj.value);
    const minVal = Math.min(...minArr);
    return minVal;
  }

  const CustomizedLabel = (props) => {
    const { x, y, value, fill } = props;
    return (
      <text x={x} y={y} dy={-15} dx={33} fill={fill} fontSize={22} fontFamily='SF-Pro-Display-Bold' textAnchor="middle" >
        {value}
      </text>
    );
  }

  function CustomizedXAxisTick(props) {
    const { x, y, payload } = props;
    const lines = payload.value.split('\n');

    if (payload.value === 'My Data') {
      return (
        <g transform={`translate(${x},${y})`}>
          {lines.map((line, index) => (
            <text key={index} x={15} y={index * 13} textAnchor="middle" fill="#333333" fontFamily='SF-Pro-Display-Medium' letterSpacing={0.2} fontSize={10}>
              {line}
            </text>
          ))}
        </g>
      )
    }

    if (payload.value === 'My Peers -\nRegion') {
      return (
        <g transform={`translate(${x},${y})`}>
          {lines.map((line, index) => (
            <text key={index} x={-15
            } y={index * 13} textAnchor="middle" fill="#333333" fontFamily='SF-Pro-Display-Medium' letterSpacing={0.2} fontSize={10}>
              {line}
            </text>
          ))}
        </g>
      )
    }

    if (payload.value === 'My Peers -\nPlatform') {
      return (
        <g transform={`translate(${x},${y})`}>
          {lines.map((line, index) => (
            <text key={index} x={-50} y={index * 13} textAnchor="middle" fill="#333333" fontFamily='SF-Pro-Display-Medium' letterSpacing={0.2} fontSize={10}>
              {line}
            </text>
          ))}
        </g>
      )
    }
  }

  const changeTab = () => {
    props.setModalOpen(false);
    if (mips) {
      setTin(true);
      setMips(false);
      setTab(responseData.result.tinData.name);
      setChartData(responseData.result.tinData.data);
    }
    else {
      setMips(true);
      setTin(false);
      setTab(responseData.result.mipsData.name);
      setChartData(responseData.result.mipsData.data);
    }
  }

  const showInformation = () => {
    setMipsScoreImpact(true);
    props.setModalOpen(true);
  }

  const triggerModal = () => {
    props.setModalOpen(true);
    setMipsScoreImpact(false);
  }

  return (
    <div>
      <div className='bg-white absolute w-full top-[9.4rem] bottom-[3.5rem] overflow-y-scroll no-scrollbar pt-4 z-10 '>
        {loading ?
          <div className="flex justify-center mt-48 mx-4">
            <div role="status">
              <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#1B453E]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          <div>
            <div className='flex justify-between bg-[#1B453E26] rounded-xl mb-4 px-2.5 py-3 cursor-pointer mx-4' onClick={triggerModal}>
              <div className='flex gap-2 items-center justify-center'>
                <p className='text-[#1B453E] font-display-medium'>{tab}</p>
                <img src={refresh} alt="refresh" />
                <p className='text-[#989899] font-display-regular text-[0.7rem]'>{date}</p>
              </div>
              <img src={greenUpDownArr} alt="dropdown" />
            </div>

            <div className='flex justify-center mx-4 mb-4'>
              <p className='font-display-regular text-lite-black text-[0.75rem] text-center tracking-wide'>Data from {month} - Comparison <br /> with Region and Platform medians</p>
            </div>

            <div className='absolute w-full mt-9'>
              <div className='bg-[#F3FAF4] h-9 border-l-4 border-[#139429] mb-[1px]'>
              </div>
              <div className='bg-[#F9FCF7] h-9 border-l-4 border-[#93BE66] mb-[1px]'>
              </div>
              <div className='bg-[#FDF3F4] h-24 border-l-4 border-[#D20F25] mb-[1px]'>
              </div>
              <div className='bg-[#FFF2F2] h-9 border-l-4 border-[#FF0004]'>
              </div>
            </div>

            {/* Bar Chart */}
            <div className='h-[17rem]'>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  dataKey="value"
                  margin={{
                    top: 35,
                    right: 0,
                    left: -20,
                    bottom: 0,
                  }}
                  barSize={72}
                >
                  <CartesianGrid vertical={false} stroke='white' />
                  <XAxis axisLine={false} interval={0} dataKey="name" tickLine={false} tick={<CustomizedXAxisTick />} tickMargin={10} />
                  <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10, fontFamily: "SF-Pro-Display-Regular", fill: "#989899" }} type="number" domain={[0, 'auto']} tickMargin={5} />
                  <Tooltip />
                  <Bar dataKey="value" label={<CustomizedLabel />}>
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} radius={[5, 5, 0, 0]} x={(index + 1) * 80} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* Regional and Platform Percentile */}
            {tab === 'MIPS' ?
              <div className='flex mt-7 justify-evenly mx-4'>
                <div className='text-[#989899] font-display-regular text-[0.8rem]'>Regional Percentile:<span className='font-display-medium text-lite-black text-sm ms-1'>50<sup>th</sup></span></div>
                <div className='text-[#989899] font-display-regular text-[0.8rem]'>Platform Percentile:<span className='font-display-medium text-lite-black text-sm ms-1'>50<sup>th</sup></span></div>
              </div> : ""}

            {/* Estimated values */}
            <div className={`bg-[#F5F5F5] p-4 rounded-lg mx-4 ${tab === 'MIPS' ? 'mt-6' : 'mt-16'} `}>
              <div className='flex justify-between'>
                <div></div>
                <div className='font-display-regular text-sm'>Estimated Final Score</div>
                <div onClick={showInformation}><img src={info} alt="info" /></div>
              </div>
              <div className='grid grid-cols-2 gap-3 mt-3'>
                <div className='bg-[#D20F25] flex rounded-lg flex-col justify-center items-center py-2'>
                  <div className='text-white text-[0.7rem] font-display-regular tracking-wider'>Estimated Minimum</div>
                  <div className='font-display-medium text-base text-white'>{findMinimum()}</div>
                </div>
                <div className='bg-[#139429] flex rounded-lg flex-col justify-center items-center py-2'>
                  <div className='text-white text-[0.7rem] font-display-regular tracking-wider'>Estimated Maximum</div>
                  <div className='font-display-medium text-base text-white'>{findMaximum()}</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      {/* Popup-modal */}
      {props.modalOpen ?
        <div className={`fixed top-0 left-0 z-30 w-full h-screen ${props.modalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110 modal'}`}>
          <div className="absolute bottom-0 left-0 w-full bg-white rounded-t-[20px]">
            {!mipsScoreImpact ?
              <div className='p-5'>
                <div className='mb-8'>
                  <img src={close} alt="close" onClick={() => props.setModalOpen(false)} className='float-right' />
                </div>
                <div className='flex flex-col justify-center items-center font-display-medium'>
                  <div><p className='text-[#1B453E] font-display-medium mb-5'>Select Option</p>
                  </div>
                  <button className={`${mips ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-6 rounded-lg mb-4 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={() => changeTab()}>MIPS</button>
                  <button className={`${tin ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-6 rounded-lg mb-8 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={() => changeTab()}>TIN</button>
                </div>
              </div> :
              <div>
                <div className='m-5 flex justify-end'>
                  <img src={close} alt="close" onClick={() => props.setModalOpen(false)} />
                </div>
                <div>
                  <p className='text-lite-black font-display-medium mb-5 text-center'>MIPS Score Impact</p>
                  <div className='bg-[#FDF3F4] w-full relative'>
                    <div className='font-display-medium text-[#1E1E1E] absolute text-[0.875rem] -rotate-90 top-20 left-5'>
                      Lose Payment
                    </div>

                    <div className='flex flex-col gap-5 ms-28 py-5'>
                      <div className='flex flex-col gap-2'>
                        <p className='text-[#FF0004] font-display-bold text-base'>
                          0.00 - 15.00
                        </p>
                        <p className=' text-lite-black text-[0.75rem] font-display-regular tracking-wide'>
                          -9% payment adjustment
                        </p>
                      </div>
                      <div className='flex flex-col gap-2'>
                        <p className='text-[#D20F25] font-display-bold text-base'>
                          15.01 - 59.99
                        </p>
                        <p className=' text-lite-black text-[0.75rem] font-display-regular tracking-wide'>
                          Negative payment adjustment <br /> (greater than -9% and less than 0%)</p>
                      </div>
                    </div>
                  </div>

                  <div className='bg-[#FFF8F5] w-full flex py-5 relative'>
                    <div className='font-display-medium text-[#1E1E1E] absolute text-[0.875rem] -rotate-90 left-8'>
                      No Change
                    </div>
                    <div className='flex flex-col gap-2 ms-28'>
                      <p className='text-[#FF7F40] font-display-bold text-base'>
                        60.00
                      </p>
                      <p className=' text-lite-black text-[0.75rem] font-display-regular tracking-wide'>
                        Neutral payment adjustment (0%)
                      </p>
                    </div>
                  </div>

                  <div className='bg-[#F3FAf4] w-full flex py-5 relative'>
                    <div className='font-display-medium text-[#1E1E1E] text-[0.875rem] -rotate-90 absolute top-32 left-3'>
                      Additional Payment
                    </div>
                    <div className='flex flex-col gap-5 ms-28'>
                      <div className='flex flex-col gap-2 pe-5'>
                        <p className='text-[#93BE66] font-display-bold text-base'>
                          60.01 - 84.99
                        </p>
                        <p className=' text-lite-black text-[0.75rem] font-display-regular tracking-wide'>
                          Positive payment adjustment (scaling factor applied to meet statutory budget neutrality requirements)
                        </p>
                      </div>

                      <div className='flex flex-col gap-2 pe-5'>
                        <p className='text-[#139429] font-display-bold text-base'>
                          85.00 - 100.00
                        </p>
                        <p className=' text-lite-black text-[0.75rem] font-display-regular tracking-wide'>
                          Positive payment adjustment (scaling factor applied to meet statutory budget neutrality requirements)
                        </p>
                        <p className='text-lite-black font-display-regular text-[0.75rem]'>
                          And
                        </p>
                        <p className=' text-lite-black text-[0.75rem] font-display-regular tracking-wide'>
                          Additional positive payment adjustment <br />(scaling factor applied to account for funding pool)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div> : null}
    </div>
  )
}

export default MIPS
