import React, { useEffect, useState } from 'react';
import TitleProfile from '../../AllComponents/TitleProfile'
// import EMGrids from "../../AllComponents/Grid";
import location from "../../../assets/img/encounterAssets/location.svg";
import next from "../../../assets/img/encounterAssets/greenRightArr.svg";
import intersection from "../../../assets/img/encounterAssets/intersection.svg";
import greenUpArr from "../../../assets/img/encounterAssets/greenUp.svg";
import updownArr from "../../../assets/img/encounterAssets/greenUpDownArr.svg";
import greenRing from "../../../assets/img/encounterAssets/greenRing.svg";
import fifty from "../../../assets/img/encounterAssets/50th.svg";
import graph from "../../../assets/img/encounterAssets/greenGraph.svg";
import SyncDate from '../../AllComponents/SyncDate';
import { BarChart, Bar, XAxis, YAxis, Cell, CartesianGrid, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import close from "../../../assets/img/clinicalAssets/close.svg";

const EncounterMetrics = () => {
    const [responseData, setResponseData] = useState({});
    const [showPrevYear, setShowPrevYear] = useState(false);
    const [showCurrYear, setShowCurrYear] = useState(true);
    const [prevYear, setPrevYear] = useState("");
    const [currYear, setCurrYear] = useState("");
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [comparison, setComparison] = useState("YTD Comparison");
    const [regionName, setRegionName] = useState("");
    const [regionLocation, setRegionLocation] = useState("");
    const [month, setMonth] = useState("");
    const [date, setDate] = useState("");
    const [periodComparisonModalOpen, setPeriodComparisonModalOpen] = useState(false);
    const [regionComparisonModalOpen, setRegionComparisonModalOpen] = useState(false);

    const [activeRegionComparison, setActiveRegionComparison] = useState("Comparison with Regional Median");

    useEffect(() => {
        const getEncounterMetricsData = async () => {
            const { data } = await axios.get('http://localhost:8081/api/dashboard/clinical/encounter-metrics/getData');
            // setLoading(true);
            setResponseData(data);
            setChartData(data.result.region[0].overallData);
            setCurrYear(data.result.region[0].currYear.name);
            setPrevYear(data.result.region[0].prevYear.name);
            setRegionName(data.result.region[0].regionName);
            setRegionLocation(data.result.region[0].location);
            setTableData(data.result.region[0].currYear.ytdData.regionalData.tableData);
            setMonth(data.result.region[0].currYear.ytdData.month);
            setDate(data.result.region[0].currYear.ytdData.date);
            // setLoading(false);
        }
        getEncounterMetricsData();
    }, [])

    console.log(setActiveRegionComparison);
    console.log(responseData);
    console.log(setComparison);
    // const colorsLight = ['#C7EAFF', '#FFE3BD', '#CBFFA9', '#DFDFFF'];

    // const colorsDark = ['#34B5FE', '#FF9300', '#7CC84A', '#8B8BEB'];

    const CustomizedLabel = (props) => {
        const { x, y, value } = props;
        return (
            <g transform={`translate(${x - 8},${y - 30})`}>
                <path id="Union_17" data-name="Union 17" d="M14.9,22.892l-1.326-1.762A2.769,2.769,0,0,1,13.088,20H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H29a3,3,0,0,1,3,3V17a3,3,0,0,1-3,3H19.367a2.767,2.767,0,0,1-.488,1.13l-1.326,1.762a1.57,1.57,0,0,1-2.652,0Z" fill="#C7EAFF" />
                <text fill="#34B5FE" fontSize={12} textAnchor="middle" dy={14} dx={15} fontFamily='SF-Pro-Display-Regular' >
                    {value}
                </text>
            </g>
        );
    }

    // const CustomizedLabel1 = (props) => {
    //     const { x, y, value, fill } = props;
    //     return (
    //         <g transform={`translate(${x + 8},${y - 28})`}>
    //             <path id="Union_17" data-name="Union 17" d="M14.9,22.892l-1.326-1.762A2.769,2.769,0,0,1,13.088,20H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H29a3,3,0,0,1,3,3V17a3,3,0,0,1-3,3H19.367a2.767,2.767,0,0,1-.488,1.13l-1.326,1.762a1.57,1.57,0,0,1-2.652,0Z" fill="#9ADAFF" />
    //             <text fill="#0082CC" fontSize={12} textAnchor="middle" dy={12} dx={15} >
    //                 {value}
    //             </text>
    //         </g>
    //     );
    // }

    const handleRegionComparison = () => {

    }

    const handlePlatformComparison = () => {

    }

    const handlePeersComparison = () => {

    }

    const handlePeriodComparison = () => {

    }

    return (
        <div className='bg-doc-bg w-full h-screen fixed'>
            <TitleProfile head="Encounter Metrics" />

            {/*Encounter metrics sub head content started */}
            <div className="bg-white rounded-t-[26px] absolute w-full top-[9rem] bottom-0 p-4 overflow-y-scroll no-scrollbar">

                {/*two grids started here */}
                <div>
                    <div className="grid grid-cols-2 gap-3">
                        <div className="info-card1 rounded-[12px] p-3 tracking-wide">
                            <div className='flex justify-between mb-2 items-center'>
                                <div className="rounded-lg bg-[#096531] h-8 w-8 flex justify-center items-center">
                                    <input type="image" src={location} alt="image" />
                                </div>
                                <div>
                                    <input type="image" src={next} alt="image" />
                                </div>
                            </div>
                            <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' onClick={""} >{regionName}</div>
                            <div className='text-white text-[0.625rem] mt-2 font-display-light tracking-wide'>{regionLocation}</div>
                        </div>
                        <div className="info-card2 rounded-[12px] p-3 tracking-wide">
                            <div className='flex justify-between mb-2 items-center'>
                                <div className="rounded-lg bg-[#4E8700] h-8 w-8 flex justify-center items-center">
                                    <input type="image" src={intersection} alt="image" />
                                </div>
                                <div onClick={() => setPeriodComparisonModalOpen(true)}>
                                    <input type="image" src={greenUpArr} alt="image" />
                                </div>
                            </div>
                            <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' onClick={() => setPeriodComparisonModalOpen(true)} >YTD Comparison</div>
                            <div className='flex justify-between items-end mt-2'>
                                <div className='text-white text-[0.625rem] font-display-light'>{month}</div>
                                <div className='flex gap-1 font-display-regular text-[0.625rem]'>
                                    <button className={`${showPrevYear ? 'bg-white text-[#4E8700]' : 'bg-transparent text-white'} border border-white rounded-xl px-[4px] py-[2px]`} onClick={() => { setShowPrevYear(!showPrevYear); setShowCurrYear(true) }}>{prevYear}</button>
                                    <button className={`${showCurrYear ? 'bg-white text-[#4E8700]' : 'bg-transparent text-white'} border border-white rounded-xl px-[4px] py-[2px]`} onClick={() => { setShowCurrYear(!showCurrYear); setShowPrevYear(true) }}>{currYear}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*comparison regional started */}
                <div>
                    <div className="mt-5">
                        <div className='flex justify-between'>
                            <div className='flex flex-col gap-1 cursor-pointer' onClick={() => setRegionComparisonModalOpen(true)}>
                                <div className='font-display-medium text-[0.75rem] text-lite-black'>
                                    Comparison with Regional Median
                                    <input type="image" img src={updownArr} className='-mb-4 ms-2' alt='reg-arr' />
                                </div>
                                <div>
                                    <SyncDate date={date} superScript="th" icon={greenRing} />
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                <input type="image" src={fifty} alt="fifty" />
                                <input type="image" src={graph} alt="graph" />
                            </div>
                        </div>
                    </div>
                </div>

                {/*Status Bar */}
                <div className='h-64 mt-[1.875rem]'>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={chartData}
                            margin={{
                                top: 15,
                                right: 0,
                                left: -15,
                                bottom: 0,
                            }}
                            barGap={3}
                        >
                            <CartesianGrid vertical={false} stroke="#EFEDED" />
                            <XAxis axisLine={false} tickLine={false} dataKey="name" tick={{ fontSize: 10, color: '#989899', fontFamily: 'SF-Pro-Display-Medium' }} tickMargin={7} width={50} />
                            <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10, color: '#989899', fontFamily: 'SF-Pro-Display-Regular' }} type="number" domain={[0, "auto"]} allowDataOverflow={true} tickMargin={5} />
                            {showCurrYear ? showPrevYear ?
                                <Bar dataKey="myDataPrev" barSize={18}>
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill='#C7EAFF' stroke="#34B5FE" strokeWidth={1} />
                                    ))}
                                </Bar> : <Bar dataKey="myDataCurr" label={<CustomizedLabel />} barSize={18}>
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill='#34B5FE' />
                                    ))}
                                </Bar> : ''}
                            {showPrevYear ?
                                <Bar dataKey="myDataPrev" label={<CustomizedLabel />} barSize={18}>
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill='#34B5FE' />
                                    ))}
                                </Bar> : ''}
                            {showCurrYear ? showPrevYear ?
                                <Bar dataKey="myPeersRegionPrev" barSize={18}>
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill='#FFE6C4' stroke="#FF9300" strokeWidth={1} />
                                    ))}
                                </Bar> : <Bar dataKey="myPeersRegionCurr" barSize={18}>
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill='#FF9300' />
                                    ))}
                                </Bar> : ''}
                            {showPrevYear ?
                                <Bar dataKey="myPeersRegionPrev" barSize={18}>
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill='#FF9300' />
                                    ))}
                                </Bar> : ''}
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                {/* <StatusTable /> */}
                <div>
                    <table className="px-4 w-full text-lite-black tracking-wide mt-2">
                        <thead className="border-b border-dashed border-collapse border border-x-0 border-t-0">
                            <tr className="font-display-regular text-[0.625rem]">
                                <th className="py-2 text-center"></th>
                                <th className="py-2 text-center">New Patient</th>
                                <th className="py-2 text-center">Follow Ups</th>
                                <th className="py-2 text-center">Surgery</th>
                                <th className="py-2 text-center">Total</th>
                            </tr>
                        </thead>
                        <tbody className='border-dashed border-collapse border border-x-0 tracking-wide'>
                            {tableData.map((data) => (
                                <tr className="border-b border-dashed border-collapse border border-x-0 font-display-medium">
                                    <td className="font-display-regular whitespace-nowrap text-[0.75rem] py-2"><span className={`border-x-[4px] border-y-[2px] rounded-[90px] ${data.name === 'My data' ? 'border-x-[#34B5FE] border-y-[#34B5FE]' : 'border-x-[#FF9300] border-y-[#FF9300]'} me-1`}></span>{data.name}{data.name === 'My Peers' ? `- ${regionName}` : ''}</td>
                                    <td className="border-dashed border-collapse border whitespace-nowrap border-l text-center text-[0.75rem] font-display-medium">{data.newPatients}</td>
                                    <td className="border-r-0 border-dashed border-collapse border whitespace-nowrap border-l text-center text-[0.75rem]">{data.followUps}</td>
                                    <td className="border-r-0 border-dashed border-collapse border whitespace-nowrap border-l text-center text-[0.75rem]">{data.surgery}</td>
                                    <td className="border-r-0 border-dashed border-collapse border whitespace-nowrap border-l text-center text-[0.75rem]">{data.total}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Period Comparison Pop-up Modal */}
            {periodComparisonModalOpen ?
                <div className={`fixed top-0 left-0 z-30 w-full h-screen ${periodComparisonModalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110'}`}>
                    <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
                        <div>
                            <div className='m-5 flex justify-end'>
                                <img src={close} alt="close" onClick={() => setPeriodComparisonModalOpen(false)} />
                            </div>
                            <div className='flex flex-col justify-center items-center font-display-medium px-4'>
                                <p className='text-[#1B453E] font-display-medium mb-5 text-base'>Select Option</p>
                                <button className={`${comparison === 'YTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handlePeriodComparison}>YTD Comparison</button>
                                <button className={`${comparison === 'MTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-[3.125rem] w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handlePeriodComparison}>MTD Comparison</button>
                            </div>
                        </div>
                    </div>
                </div> : ''}

            {/*  comparison Pop-up Modal */}
            {regionComparisonModalOpen ?
                <div className={`fixed top-0 left-0 z-30 w-full h-screen ${regionComparisonModalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110'}`}>
                    <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
                        <div>
                            <div className='m-5 flex justify-end'>
                                <img src={close} alt="close" onClick={() => setRegionComparisonModalOpen(false)} />
                            </div>
                            <div className='flex flex-col justify-center items-center font-display-medium px-4'>
                                <p className='text-[#1B453E] font-display-medium mb-5 text-base'>Select Comparison</p>
                                <button className={`${activeRegionComparison === 'Comparison with Regional Median' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleRegionComparison}>Comparison with Regional Median</button>
                                <button className={`${activeRegionComparison === 'Comparison with Platform' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handlePlatformComparison}>Comparison with Platform</button>
                                <button className={`${activeRegionComparison === 'Comparison with Peers' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-[3.125rem] w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handlePeersComparison}>Comparison with Peers</button>
                            </div>
                        </div>
                    </div>
                </div> : ''}
        </div>
    )
}

export default EncounterMetrics