import React from 'react';
import { useNavigate } from 'react-router-dom';

const Splash = () => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     const moveToDashboard = () => {
    //         setTimeout(function(){
    //             navigate('/dashboard');
    //         }, 3000);
    //     }
    //     moveToDashboard();
    // },[navigate]);

    return (
        <div className='bg-splash bg-cover min-h-screen flex flex-col' onClick={() => navigate("/dashboard")}>
        </div>
    )
}

export default Splash
