import React, { useEffect, useState } from 'react';
import notification from "../../assets/img/dashboardAssets/notification.svg";
import profile from "../../assets/img/dashboardAssets/profileImage.png";
import arrows from "../../assets/img/dashboardAssets/Arrows.svg";
import info from "../../assets/img/dashboardAssets/Info.svg";
import redArrow from "../../assets/img/dashboardAssets/redArrow.svg";
import DashFooter from './DashFooter';
import axios from "axios";
import greenArrow from "../../assets/img/dashboardAssets/greenArrow.svg";
import like from "../../assets/img/dashboardAssets/like.svg";
import { useNavigate } from 'react-router-dom';
import Loader from '../AllComponents/Loader';

const Dashboard = () => {
    const [selectedTab, setSelectedTab] = useState('');
    const [selectedTabData, setSelectedTabData] = useState([]);
    const [dashData, setDashData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getDashboardData = async () => {
            setLoading(true);
            const { data } = await axios.get('https://drkpeyes-api.ignithocloud.com/api/dashboard/getData');
            setDashData(data.result);
            setSelectedTab(data.result[0].tab_name);
            setSelectedTabData(data.result[0].tab_data);
            setLoading(false);
        }
        getDashboardData();
    }, [])

    const selectTabs = (index) => {
        setSelectedTab(dashData[index].tab_name);
        setSelectedTabData(dashData[index].tab_data);
    }

    return (
        <div>
            <div className='bg-doc-bg h-screen'>
                <div className='flex justify-between px-4 pt-16 pb-4'>
                    <div>
                        <p className='text-sm text-white font-display-regular'>Good Morning ,</p>
                        <p className='text-base font-display-medium text-[#8DC63F]'>Dr. Doug Wisor</p>
                    </div>
                    <div className='flex gap-3 items-center justify-center'>
                        <div className='bg-white flex items-center rounded-full h-11 w-11 justify-center'>
                            <img src={like} alt="like" />
                        </div>
                        <div className='bg-white flex items-center rounded-full h-11 w-11 justify-center'>
                            <img src={notification} alt="notification" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='bg-white rounded-full h-11 w-11 absolute'>
                            </div>
                            <img src={profile} className='rounded-full h-10 w-10 relative' alt="profile" />
                        </div>
                    </div>
                </div>

                <div className='border-b border-white mx-4'></div>

                <div className='flex mx-4 justify-between py-3'>
                    {dashData.map((tab, index) => (
                        <button key={index} onClick={() => selectTabs(index)} value={tab.tab_name} className={`font-display-regular tracking-wide text-sm opacity-100 ${tab.tab_name === selectedTab ? 'bg-[#8DC63F] rounded-[10px] px-4 py-1 text-[#1B453E] border border-white' : 'text-white'}`}>{tab.tab_name}</button>
                    ))}
                </div>

                <div className='bg-white rounded-t-[20px] absolute w-full top-[11.3rem] bottom-[3.5rem] overflow-y-scroll no-scrollbar'>
                    <div className='p-4'>
                        {selectedTab !== 'Community' ?
                            <div className='flex justify-between bg-[#1B453E26] h-10 rounded-[15px] p-3'>
                                <div className='flex items-center justify-center'>
                                    <div className='font-display-medium text-[#194039] me-3 text-[0.8rem]'>QTD 2023 vs QTD 2022</div>
                                    <img src={arrows} alt="menu" />
                                </div>
                                <div>
                                    <img src={info} alt="info" />
                                </div>
                            </div> : ''}
                        {loading ? <div className="flex justify-center mt-48">
                            <Loader />
                        </div> :
                            <div className='grid grid-cols-3 gap-x-3 gap-y-5 mt-8'>
                                {selectedTabData.map((data, index) => (
                                    <>
                                        {data.cardType === 'nonStatic' ?
                                            <div className='bg-[#F1F1F1] rounded-xl p-3' key={index} onClick={() => navigate(`/${data.route}`)}>
                                                <div className='flex justify-between'>
                                                    <img src={data.previousValue < data.currentValue ? data.greenIcon : data.redIcon} alt="icon" className='object-cover' />
                                                    <img src={data.previousValue < data.currentValue ? greenArrow : redArrow} alt="uparrow" className='object-cover' />
                                                </div>
                                                <div className={`font-display-bold mt-2 text-[1.5rem] ${data.previousValue < data.currentValue ? 'text-[#32CD32]' : 'text-[#FF0000] '}`}>{data.value}{' '}<span className='text-base'>{data.days ? "Days" : ""}</span></div>
                                                <div className='font-display-regular text-lite-black text-[0.75rem] tracking-wide'>{data.item}</div>
                                            </div> : null}
                                        {data.cardType === 'static' ?
                                            <div className='dash-card rounded-xl p-2' key={index} onClick={() => navigate(`/${data.route}`)}>
                                                <div className='flex justify-center'>
                                                    <img src={data.icon} alt="icon" className='object-cover pt-3' />
                                                </div>
                                                <div className='text-[0.75rem] text-center font-display-regular text-[#1B453E] px-4 py-3'>{data.item}</div>
                                            </div >
                                            : null}
                                    </>
                                ))}
                            </div>}
                    </div>
                </div>
                <DashFooter dashboard={true} />
            </div>
        </div >

    )
}

export default Dashboard
