import React, { useEffect, useState } from 'react';
import backArr from "../../../../assets/img/encounterAssets/Backward arrow.svg";
import SyncDate from '../../../AllComponents/SyncDate';
import greenRing from "../../../../assets/img/encounterAssets/greenRing.svg";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from "axios";
import MonthlyTrendsTable from '../../../AllComponents/MonthlyTrendsTable';

const ChargesTrends = (props) => {
  // const [loading, setLoading] = useState(false);
  const [monthlyTrendsData, setMonthlyTrendsData] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const getChargesMonthlyTrendsData = async () => {
      // setLoading(true);
      const { data } = await axios.get('http://localhost:8081/api/dashboard/clinical/charges-monthly-trends/getData');
      console.log(data);
      setResponseData(data);
      setMonthlyTrendsData(data.result);
      setChartData(data.overallData);
      // setLoading(false);
    }
    getChargesMonthlyTrendsData();
  }, []);

  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const lines = payload.value.split(' ');
    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <text key={index} x={0} y={index * 13} textAnchor="middle" fill="#989899" fontSize={8}>
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <div className='bg-doc-bg fixed top-0 left-0 w-full h-screen'>
      <div className='flex justify-between px-4 mt-[3.75rem]'>
        <div onClick={() => props.setChargesMonthlyTrendsOpen(false)}><img src={backArr} alt="back" /></div>
        <div className='text-white text-base font-display-light tracking-wider'>Charges - Monthly Trends</div>
        <div></div>
      </div>

      <div className='bg-white mt-5 rounded-t-[1.5rem] px-4'>
        <div className='py-4'>
          <SyncDate date="Jun 30" superScript="th" icon={greenRing} />
        </div>
        <div className='absolute top-[9.2rem] left-0 bottom-0 bg-white w-full overflow-y-scroll no-scrollbar'>
          <div style={{ height: "200px", width: '100%' }}>
            <ResponsiveContainer>
              <AreaChart
                data={chartData}
                margin={{
                  top: 5,
                  right: 20,
                  left: -25,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="15%" stopColor="#34B5FE" stopOpacity={0.7} />
                    <stop offset="90%" stopColor="#34B5FE" stopOpacity={0.3} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="15%" stopColor="#FF9300" stopOpacity={0.7} />
                    <stop offset="98%" stopColor="#FF9300" stopOpacity={0.3} />
                  </linearGradient>
                </defs>
                <CartesianGrid horizontal={false} stroke="#98989866" strokeWidth={0.4} />
                <XAxis axisLine={false} tickLine={false} interval={0} dataKey="name" tick={<CustomizedXAxisTick />} tickMargin={7} />
                <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 8, fill: "#989899", fontFamily: 'SF-Pro-Display-Regular' }} type="number" domain={[0, 'auto']} allowDataOverflow={true} tickMargin={5} />
                <Tooltip />
                <Area type="monotone" dataKey="myData" stroke="#34B5FE" fillOpacity={1} fill="url(#colorUv)" dot={{ stroke: '#FFFFFF', strokeWidth: 1, fill: '#34B5FE', r: 4 }} />
                <Area type="monotone" dataKey="previousYear" stroke="#989899" fillOpacity={1} fill="url(#colorPv)" dot={{ stroke: '#FFFFFF', strokeWidth: 1, fill: '#FF9300', r: 4 }} />
              </AreaChart>
            </ResponsiveContainer>
          </div>

          <div className='mx-4 mt-4'>
            <table className="font-display-medium text-center text-[0.625rem] text-lite-black w-full table-fixed" >
              <tbody>
                <tr>
                  <td className='py-2'>Month</td>
                  <td><span className="w-2.5 h-2.5 bg-[#34B5FE] inline-block rounded-full me-1"></span>My Data</td>
                  <td><span className="w-2.5 h-2.5 bg-[#FF9300] inline-block rounded-full me-1"></span>Previous Year</td>
                  <td className='py-2'>Difference</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <MonthlyTrendsTable tableData={monthlyTrendsData} />
            <MonthlyTrendsTable avg={true} myDataOverallTotal={responseData.myDataOverallTotal} previousYearOverallTotal={responseData.previousYearOverallTotal} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChargesTrends
