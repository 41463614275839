import React from 'react';

const Grid = (props) => {
    return (
        <div >
            <div className={props.classname}>
                <div className='flex justify-between'>
                    <div className={props.icolor}>
                        <input type="image" src={props.icon} alt="image" className='h-5' />
                    </div>
                    <div className='rounded h-8 flex justify-center items-center my-2'>
                        <input type="image" src={props.rightI} alt="image" />
                    </div>
                </div>

                <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' >{props.title}</div>
                {props.compare ? props.years ?
                    <div className='flex justify-between items-center mt-1'>
                        <div className={props.subcolor}>{props.subTitle}</div>
                        <div className='flex gap-1 font-display-regular text-[0.625rem]'>
                            <button className={`${props.year1Classname} border border-white rounded-xl px-[4px] py-[2px]`} >{props.year1}</button>
                            <button className={`${props.year2Classname} border border-white rounded-xl px-[4px] py-[2px] `} >{props.year2}</button>
                        </div>
                    </div> :

                    <div>
                        <div className={props.subcolor}>{props.subTitle}</div>
                        <div className='text-white font-display-regular text-[0.625rem] tracking-wide text-right mt-[-13px]'> {props.content}</div>
                    </div> : <div className={props.subcolor}>{props.subTitle}</div>
                }
            </div>
        </div>
    )
}

export default Grid