import React, { useState } from 'react';
import dashboard from '../../assets/img/dashboardAssets/Dashboard.svg';
import others from '../../assets/img/dashboardAssets/Others.svg';
import profile from '../../assets/img/dashboardAssets/Profile.svg';
import dashboardWhite from '../../assets/img/dashboardAssets/dashboardWhite.svg';
import profileWhite from '../../assets/img/dashboardAssets/profileWhite.svg';
import { useNavigate } from 'react-router-dom';

const DashFooter = (props) => {
    const navigate = useNavigate();
    const [dashboardActive, setDashboardActive] = useState(props.dashboard);
    const [profileActive, setProfileActive] = useState(props.profile);

    return (
        <div className={`absolute bottom-0 w-full z-20 ${props.profile ? 'bg-[#DDE3E2]' : 'bg-white'}`}>
            <div className='flex rounded-t-[15px] items-center justify-between px-11 bg-white h-[3.5rem] drop-shadow-[0_0_10px_#0000001a]'>
                <div onClick={() => { navigate('/dashboard'); setDashboardActive(true); setProfileActive(false) }} className='duration-500'>
                    {dashboardActive ?
                        <div className='flex flex-col items-center gap-2 -mt-[44px]'>
                            <div className='rounded-full bg-[#1B453E] h-14 w-14 flex border border-white justify-center items-center shadow-[0_0_0_7px_#efeded]'>
                                <img src={dashboardWhite} alt="dashboard-active" className='object-cover' />
                            </div>
                            <p className='font-display-medium text-[0.75rem]'>Dashboard</p>
                        </div>
                        : <img src={dashboard} alt="dashboard" className='object-cover' />}
                </div>
                <div onClick={() => { navigate('/profile'); setProfileActive(true); setDashboardActive(false) }} className='duration-500'>
                    {profileActive ?
                        <div className='flex flex-col items-center gap-1 -mt-[44px]'>
                            <div className='rounded-full bg-[#1B453E] h-14 w-14 flex border border-white justify-center items-center shadow-[0px_0px_0px_7px_#DDE3E2]'>
                                <img src={profileWhite} alt="profile-active" />
                            </div>
                            <p className='font-display-medium text-[0.75rem]'>Profile</p>
                        </div> : <img src={profile} alt="profile" className='object-cover' />}
                </div>
                <div>
                    <img src={others} alt="others" className='object-cover' />
                </div>
            </div>
        </div>
    )
}

export default DashFooter
