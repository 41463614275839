import React, { useState } from 'react';
import back from "../../../assets/img/npBookingWindowAssets/backArrow.svg";
import { useNavigate } from 'react-router-dom';
import NPS from './MIPS/NPS';
import MIPS from './MIPS/MIPS';
import GoogleRating from './MIPS/GoogleRating';
import DashFooter from '../DashFooter';

const MipsDash = () => {
  const [nps, setNps] = useState(false);
  const [googleRating, setGoogleRating] = useState(false);
  const [mips, setMips] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <div className='bg-doc-bg h-screen fixed top-0 left-0 w-full'>
      <div className=''>
        <div className='w-full h-32 absolute top-0 flex justify-between pt-14 px-4'>
          <div className="cursor-pointer" onClick={() => navigate('/dashboard')}><img src={back} alt="back" /></div>
          <div className="text-[#FFFFFF] text-base font-display-light tracking-wide">Comparative Analytics</div>
          <div></div>
        </div>

        <div className="bg-white rounded-t-[26px] px-4 relative mt-24">
          <div className="grid grid-cols-3 gap-3 py-4 font-display-regular text-sm">
            <button className={`rounded-[12px] py-2 ${nps ? 'bg-[#8DC63F] text-white' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setNps(true); setGoogleRating(false); setMips(false) }}>NPS</button>
            <button className={`rounded-[12px] py-2 ${googleRating ? 'bg-[#8DC63F] text-white' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setGoogleRating(true); setNps(false); setMips(false) }}>Google Rating</button>
            <button className={`rounded-[12px]  py-2 ${mips ? 'bg-[#8DC63F] text-white' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setMips(true); setGoogleRating(false); setNps(false) }}>MIPS</button>
          </div>
        </div>
      </div>
      {nps ? <NPS /> : ''}
      {googleRating ? <GoogleRating /> : ''}
      {mips ? <MIPS setModalOpen={setModalOpen} modalOpen={modalOpen} /> : ''}
      <DashFooter />
    </div>
  )
}

export default MipsDash
