import React, { useEffect, useState } from 'react'
import TitleProfile from '../../AllComponents/TitleProfile'
// import DICGrids from "../../AllComponents/Grid";
import location from "../../../assets/img/encounterAssets/location.svg";
import rightblue from "../../../assets/img/encounterAssets/greenRightArr.svg";
import intersection from "../../../assets/img/encounterAssets/intersection.svg";
import updownArr from "../../../assets/img/clinicalAssets/whiteUpDownArr.svg";
import greenRing from "../../../assets/img/encounterAssets/greenRing.svg";
import SyncDate from '../../AllComponents/SyncDate';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Cell } from 'recharts';
import DashFooter from '../DashFooter';
import axios from 'axios';
import close from "../../../assets/img/clinicalAssets/close.svg";
import Centers from '../../AllComponents/Centers';

const DaysInClinic = () => {
  const [responseData, setResponseData] = useState({});
  const [preYear, setPreYear] = useState(false);
  const [currYear, setCurrYear] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [comparison, setComparison] = useState("YTD Comparison");
  const [month, setMonth] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [selectCentersPageOpen, setSelectCentersPageOpen] = useState(false);
  const [allCenters, setAllCenters] = useState(true);
  const [consolidatedCenters, setConsolidatedCenters] = useState(false);
  const [selectedCentersName, setSelectedCentersName] = useState("");
  const [showPrevYear, setShowPrevYear] = useState(false);
  const [showCurrYear, setShowCurrYear] = useState(true);

  console.log(setPreYear);
  console.log(setCurrYear);
  console.log(month);
  console.log(setShowPrevYear);
  console.log(showCurrYear);
  console.log(setShowCurrYear);
  console.log(setAllCenters);

  useEffect(() => {
    const getDaysInClinicData = async () => {
      const { data } = await axios.get('http://localhost:8081/api/dashboard/clinical/days-in-clinic/getData');
      // setLoading(true);
      setResponseData(data);
      setChartData(data.result.yearlyData.overallData.data);
      setMonth(data.result.month);
      // setLoading(false);
    }
    getDaysInClinicData();
  }, [])

  const colorsLight = ['#C7EAFF', '#FFE3BD', '#CBFFA9', '#DFDFFF'];

  const colorsDark = ['#34B5FE', '#FF9300', '#7CC84A', '#8B8BEB'];

  const CustomizedLabel = (props) => {
    const { x, y, value } = props;
    return (
      <g transform={`translate(${x - 8},${y - 28})`}>
        <path id="Union_17" data-name="Union 17" d="M14.9,22.892l-1.326-1.762A2.769,2.769,0,0,1,13.088,20H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H29a3,3,0,0,1,3,3V17a3,3,0,0,1-3,3H19.367a2.767,2.767,0,0,1-.488,1.13l-1.326,1.762a1.57,1.57,0,0,1-2.652,0Z" fill="#C7EAFF" />
        <text fill="#34B5FE" fontSize={12} textAnchor="middle" dy={12} dx={15}>
          {value}
        </text>
      </g>
    );
  }

  const CustomizedLabel1 = (props) => {
    const { x, y, value } = props;
    return (
      <g transform={`translate(${x + 8},${y - 28})`}>
        <path id="Union_17" data-name="Union 17" d="M14.9,22.892l-1.326-1.762A2.769,2.769,0,0,1,13.088,20H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H29a3,3,0,0,1,3,3V17a3,3,0,0,1-3,3H19.367a2.767,2.767,0,0,1-.488,1.13l-1.326,1.762a1.57,1.57,0,0,1-2.652,0Z" fill="#C7EAFF" />
        <text fill="#34B5FE" fontSize={12} textAnchor="middle" dy={13} dx={15} >
          {value}
        </text>
      </g>
    );
  }

  const changeComparison = () => {
    setModalOpen(false);
    if (comparison === 'YTD Comparison') {
      setComparison("MTD Comparison");
      setMonth(responseData.result.monthlyData.month);
      setChartData(responseData.result.monthlyData.overallData);
    }
    else {
      setComparison('YTD Comparison');
      setMonth(responseData.result.month);
      setChartData(responseData.result.yearlyData.overallData);
    }
  }

  const handleCenters = () => {
    setSelectCentersPageOpen(true);
  }

  return (
    <div>
      {!selectCentersPageOpen ?
        <div className='bg-[#1B453E] w-full h-screen fixed'>
          <TitleProfile head="Days in Clinic" />

          {/*Days in clinic sub head content started */}
          <div className="bg-white rounded-t-[26px] absolute w-full top-[9rem] bottom-[3.5rem] overflow-y-scroll no-scrollbar">
            <div className="px-4 mt-2 mx-auto">
              {/*two grids started here */}
              <div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="info-card1 rounded-[12px] p-3 tracking-wide">
                    <div className='flex justify-between mb-2 items-center'>
                      <div className="rounded-lg bg-[#096531] h-8 w-8 flex justify-center items-center">
                        <input type="image" src={location} alt="image" />
                      </div>
                      <div>
                        <input type="image" src={rightblue} alt="image" />
                      </div>
                    </div>
                    <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' onClick={handleCenters}>{allCenters ? "All centers" : consolidatedCenters ? "All Centers" : selectedCentersName}</div>
                    <div className='text-white text-[0.625rem] mt-2 font-display-light tracking-wide'>400 E Wayne Ave, MD 20901, US</div>
                  </div>
                  <div className="info-card2 rounded-[12px] p-3 tracking-wide">
                    <div className='flex justify-between mb-2 items-center'>
                      <div className="rounded-lg bg-[#4E8700] h-8 w-8 flex justify-center items-center">
                        <input type="image" src={intersection} alt="image" />
                      </div>
                      <div onClick={() => setModalOpen(true)}>
                        <input type="image" src={updownArr} alt="image" />
                      </div>
                    </div>

                    <div className='text-white text-[0.875rem] font-display-light cursor-pointer tracking-wide' onClick={() => setModalOpen(true)} >YTD Comparison</div>
                    <div className='flex justify-between items-end mt-2'>
                      <div className='text-white text-[0.625rem] font-display-light'>Jan - Jun</div>
                      <div className='flex gap-1 font-display-regular text-[0.625rem]'>
                        <button className={`${showPrevYear ? 'bg-white text-[#4E8700]' : 'bg-transparent text-white'} border border-white rounded-xl px-[4px] py-[2px]`} onClick={""}>2022</button>
                        <button className={`${showPrevYear ? 'bg-white text-[#4E8700]' : 'bg-transparent text-white'} border border-white rounded-xl px-[4px] py-[2px]`} onClick={""}>2023</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Date*/}
              <div className='mt-5'>
                <SyncDate date="Jun 30" superScript="th" icon={greenRing} />
              </div>

              {/*Status bar chart */}
              <div className='h-[17rem] mt-10'>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={chartData}
                    margin={{
                      top: 15,
                      right: 0,
                      left: -30,
                      bottom: 5,
                    }}
                    barGap={6}
                  >
                    <CartesianGrid vertical={false} stroke="#EFEDED" />
                    <XAxis axisLine={false} tickLine={false} dataKey="name" tick={{ fontSize: 11, color: '#989899' }} tickMargin={7} width={50} interval={0}/>
                    <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10, color: '#989899' }} type="number" domain={[0, "auto"]} allowDataOverflow={true} tickMargin={5} />
                    {preYear ? currYear ?
                      <Bar dataKey="prevYear" label={<CustomizedLabel />} barSize={25}>
                        {chartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colorsLight[index % 20]} stroke={colorsDark[index % 20]} strokeWidth="1.5px" />
                        ))}
                      </Bar> : <Bar dataKey="prevYear" label={<CustomizedLabel />} barSize={50}>
                        {chartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colorsDark[index % 20]} stroke={colorsDark[index % 20]} strokeWidth="1.5px" />
                        ))}
                      </Bar> : ''}
                    {currYear ? preYear ?
                      <Bar dataKey="currentYear" label={<CustomizedLabel1 />} barSize={25}>
                        {chartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colorsDark[index % 20]} />
                        ))}
                      </Bar> : <Bar dataKey="currentYear" label={<CustomizedLabel1 />} barSize={50}>
                        {chartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colorsDark[index % 20]} />
                        ))}
                      </Bar> : ''}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          {/* Footer */}
          <div>
            <DashFooter />
          </div>
          {/* Pop-up Modal */}
          <div className={`fixed top-0 left-0 z-30 w-full h-screen ${modalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110 modal'}`}>
            <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
              <div>
                <div className='m-5 flex justify-end'>
                  <img src={close} alt="close" onClick={() => setModalOpen(false)} />
                </div>
                <div className='flex flex-col justify-center items-center font-display-medium px-4'>
                  <p className='text-[#1B453E] font-display-medium mb-5 text-base'>Select Option</p>
                  <button className={`${comparison === 'YTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={() => changeComparison()}>YTD Comparison</button>
                  <button className={`${comparison === 'MTD Comparison' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-[3.125rem] w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={() => changeComparison()}>MTD Comparison</button>
                </div>
              </div>
            </div>
          </div>
        </div> : <Centers selectedCenters={selectedCenters} setSelectedCenters={setSelectedCenters} setSelectCentersPageOpen={setSelectCentersPageOpen} consolidatedCenters={consolidatedCenters} setConsolidatedCenters={setConsolidatedCenters} setSelectedCentersName={setSelectedCentersName} selectedCentersName={selectedCentersName} />
      }
    </div>

  )
}

export default DaysInClinic
