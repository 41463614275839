import React from 'react'
import backArr from "./../../assets/img/encounterAssets/Backward arrow.svg";
import info from "../../assets/img/encounterAssets/Group 829.svg";
import profile from "../../assets/img/encounterAssets/Group 3574.svg";
import switchIcon from "../../assets/img/encounterAssets/Group 3154.svg";
import { useNavigate } from 'react-router-dom';

const TitleProfile = (props) => {
  const navigate = useNavigate();

  return (
    <div className='justify-between px-4'>
      <div className="flex justify-between pt-14">
        <div className="backArrow" onClick={() => navigate(-1)}><img src={backArr} alt="backarrow" /></div>
        <div className="title text-white font-display-light tracking-wide text-base"><h2>{props.head}</h2></div>
        <div className="info"><img src={info} alt="infoIcon" /></div>
      </div>

      <div className='align-middle pt-1'>
        <div className="flex justify-between">
          <div className="col flex">
            <div className='en-data flex items-center gap-2'>
              <img src={profile} className='en-pro-img' alt='pro-img' />
              <p className='flex text-sm font-display-regular text-[#8DC63F]'>My Data</p>
            </div>
          </div>
          <div className="col flex justify-end">
            <div className='flex items-center -me-4'>
              <p className='en-switch-name flex align-middle text-sm font-thin text-white'>Switch to NP/PA</p>
              <img src={switchIcon} className='en-pro-img' alt='pro-img' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TitleProfile
