import React from 'react'

const RectangleBtn = (props) => {

    return (
        <div>
            <button
                type="button"
                className={`flex items-center rounded-lg font-regular w-[10.75rem] h-9 text-center justify-center text-[0.90rem] ${props.showColor ? 'bg-greenrectbtn text-white'  : 'bg-greyrectbtn text-black' } `}
                onClick={() => props.showChart()}
            >
                {props.value}
            </button>
        </div>
    )
}

export default RectangleBtn
