import React from 'react';
import profile from '../../../../assets/img/OperationalAssets/Profile.svg';
import downArrow from '../../../../assets/img/OperationalAssets/downArrow.svg';

const SurgeriesDash = () => {
  return (
    <div className='absolute top-[11rem] w-full left-0'>
      <div className='bg-[#1B453E26] mx-4 rounded-md'>
        <div className='flex justify-between py-3'>
          <div>
            <div className='flex ms-2.5'>
              <img src={profile} alt="profile" className='' />
              <div className='font-display-medium text-[#1B453E]'>Payers : <span className='text-lite-black text-sm'>All Payers</span></div>
            </div>
            <div className=''>
              <img src={downArrow} alt="down-arrow"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default SurgeriesDash
