import React, { useState } from 'react';
import OpenKPI from './OpenKPI';
import back from "../../../assets/img/npBookingWindowAssets/backArrow.svg";
import Summary from './Summary';
import Surgeries from './Surgeries';
import { useNavigate } from 'react-router-dom';

const NpBookingWindow = () => {
  const [summary, setSummary] = useState(false);
  const [openKPI, setOpenKPI] = useState(true);
  const [surgeries, setSurgeries] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <div className='bg-[#1B453E] fixed w-full top-0 z-20'>
        <div className="flex justify-between px-4 pb-4 pt-[3.75rem]">
          <div className="cursor-pointer" onClick={() => navigate('/dashboard')}><img src={back} alt="back" /></div>
          <div className="text-[#FFFFFF] text-base font-display-medium">Comparative Analytics</div>
          <div></div>
        </div>

        <div className="bg-white rounded-t-[26px] px-4 pb-5">
          <div className="grid grid-cols-3 gap-3 pt-4 text-sm">
            <button className={`rounded-[12px] font-display-regular py-2 ${summary ? 'bg-[#8DC63F] text-white cursor-auto pointer-events-none' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setSummary(true); setOpenKPI(false); setSurgeries(false) }}>Summary</button>
            <button className={`rounded-[12px] font-display-regular py-2 ${openKPI ? 'bg-[#8DC63F] text-white cursor-auto pointer-events-none' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setOpenKPI(true); setSummary(false); setSurgeries(false) }}>Open KPI</button>
            <button className={`rounded-[12px] font-display-regular py-2 ${surgeries ? 'bg-[#8DC63F] text-white cursor-auto pointer-events-none' : 'text-lite-black bg-[#EFEDED]'}`} onClick={() => { setSurgeries(true); setSummary(false); setOpenKPI(false) }}>Surgeries</button>
          </div>
        </div>
      </div>
      {summary ? <Summary /> : ''}
      {openKPI ? <OpenKPI /> : ''}
      {surgeries ? <Surgeries /> : ''}
    </div>
  )
}

export default NpBookingWindow
