import React from 'react'

const SyncDate = (props) => {
    return (
        <div className='flex gap-1'>
            <input type="image" src={props.icon} alt="image" className='w-2.2' />
            <p className='text-[0.62rem] text-lite-black-1'>{props.date}<sup>{props.superScript}</sup></p>
        </div>
    )
}

export default SyncDate