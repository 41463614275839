import React, { useEffect, useState } from 'react';
import circle from "../../../assets/img/npBookingWindowAssets/circle.svg";
import info from "../../../assets/img/npBookingWindowAssets/info.svg";
import circleB from "../../../assets/img/npBookingWindowAssets/circle-black.svg";
import infoB from "../../../assets/img/npBookingWindowAssets/info-black.svg";
import arrowNext from "../../../assets/img/npBookingWindowAssets/forwardArrow.svg";
import calendar from "../../../assets/img/npBookingWindowAssets/calendar.svg";
import axios from "axios";
import close from "../../../assets/img/clinicalAssets/close.svg";
import GaugeChart from 'react-gauge-chart';

const OpenKPI = () => {
  const [responseData, setResponseData] = useState();
  const [openKPIData, setOpenKPIData] = useState([]);
  const [period, setPeriod] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [noOfDays, setNoOfDays] = useState(0);
  const [legendModalOpen, setLegendModalOpen] = useState(false);
  const [comparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [daysLegend, setDaysLegend] = useState(false);

  console.lof(noOfDays);
  
  useEffect(() => {
    const getOpenKPIData = async () => {
      const { data } = await axios.get('http://localhost:8081/api/dashboard/operations/comparative-analytics/np-booking-window');
      setResponseData(data);
      setPeriod(data.result.openKPI.period2.period);
      setMonth(data.result.openKPI.period2.month);
      setDate(data.result.openKPI.period2.date);
      setNoOfDays(data.result.openKPI.period2.noOfDays);
      setOpenKPIData(data.result.openKPI.period2.data);
    }
    getOpenKPIData();
  }, [])

  const chartStyle = {
    width: 80
  }

  const handleLastMonthData = () => {
    setPeriod(responseData.result.openKPI.period1.period);
    setMonth(responseData.result.openKPI.period1.month);
    setDate(responseData.result.openKPI.period1.date);
    setOpenKPIData(responseData.result.openKPI.period1.data);
    setComparisonModalOpen(false);
  }

  const handleLast3MonthsData = () => {
    setPeriod(responseData.result.openKPI.period2.period);
    setMonth(responseData.result.openKPI.period2.month);
    setDate(responseData.result.openKPI.period2.date);
    setOpenKPIData(responseData.result.openKPI.period2.data);
    setComparisonModalOpen(false);
  }

  const handleLast6MonthsData = () => {
    setPeriod(responseData.result.openKPI.period3.period);
    setMonth(responseData.result.openKPI.period3.month);
    setDate(responseData.result.openKPI.period3.date);
    setOpenKPIData(responseData.result.openKPI.period3.data);
    setComparisonModalOpen(false);
  }

  const handleLast12MonthsData = () => {
    setPeriod(responseData.result.openKPI.period4.period);
    setMonth(responseData.result.openKPI.period4.month);
    setDate(responseData.result.openKPI.period4.date);
    setOpenKPIData(responseData.result.openKPI.period4.data);
    setComparisonModalOpen(false);
  }

  const handleLegend = (cardName) => {
    if (cardName === 'NP Booking Window') {
      setDaysLegend(true);
    }
    else {
      setDaysLegend(false);
    }
    setLegendModalOpen(true);
  }

  const handleComparisonModalOpen = (e) => {
    setComparisonModalOpen(true);
  }

  return (
    <div>
      <div>
        <div className='bg-white px-4 fixed w-full top-[10.375rem] pb-3 cursor-pointer z-20' onClick={handleComparisonModalOpen}>
          <div className='flex justify-between items-center'>
            <div className='flex items-center gap-3'>
              <div>
                <img src={calendar} alt="calendar" />
              </div>
              <div className='flex flex-col font-display-medium text-sm'>
                <p className='text-lite-black'>{period}</p>
                <p className='text-[#888888]'>{month}</p>
              </div>
            </div>
            <div>
              <img src={arrowNext} alt="next" />
            </div>
          </div>
          <hr className='mt-1' />
        </div>
        <div className='bg-white p-4 z-10 absolute w-full top-[12.5rem] bottom-0 overflow-y-scroll no-scrollbar'>
          {openKPIData.map((obj, index) => (
            <div className={` ${obj.compare ? obj.name === 'NP Booking Window' ? obj.myData < 5 ? 'border-[#18B854]' : obj.myData >= 6 && obj.myData <= 7 ? 'border-[#F7943C]' : obj.myData > 7 ? 'border-[#DF1616]' : 'border-[#D6D6D6]' : obj.name === 'No Show Rate YoY' || obj.name === 'Surgeries Without Prior Auth' ? obj.myData < 10 ? 'border-[#18B854]' : obj.myData >= 10 && obj.myData <= 15 ? 'border-[#F7943C]' : obj.myData > 15 ? 'border-[#DF1616]' : 'border-[#D6D6D6]' : 'border-[#D6D6D6]' : 'border-[#D6D6D6]'} border mt-4 rounded-[13px] bg-[#F5F5F5]`} key={index}>
              <div className={`flex justify-between ${obj.compare ? obj.name === 'NP Booking Window' ? obj.myData < 5 ? 'bg-[#18B854]' : obj.myData >= 6 && obj.myData <= 7 ? 'bg-[#F7943C]' : obj.myData > 7 ? 'bg-[#DF1616]' : 'bg-[#D6D6D6]' : obj.name === 'No Show Rate YoY' || obj.name === 'Surgeries Without Prior Auth' ? obj.myData < 10 ? 'bg-[#18B854]' : obj.myData >= 10 && obj.myData <= 15 ? 'bg-[#F7943C]' : obj.myData > 15 ? 'bg-[#DF1616]' : 'bg-[#D6D6D6]' : 'bg-[#D6D6D6]' : 'bg-[#D6D6D6]'} rounded-[9px] py-1 px-2 items-center`}>
                <div>
                  <img src={`${obj.compare ? circle : circleB}`} alt="circle" className='absolute' />
                  <p className={`font-display-regular text-[0.625rem] relative ms-3 ${obj.compare ? 'text-white' : 'text-lite-black'}`}>{date}<sup>th</sup></p>
                </div>
                <p className={`font-display-medium text-sm ${obj.compare ? 'text-white' : 'text-lite-black'} tracking-wide`}>{obj.name}</p>
                <img src={`${obj.compare ? info : infoB}`} alt="info" onClick={() => handleLegend(obj.name)} />
              </div>
              <div className='flex justify-around py-3'>
                {obj.graph ? <div>
                  <GaugeChart id="gauge-chart1" percent={obj.name === 'NP Booking Window' ? obj.myData / 10 : obj.myData / 100} animate={false} nrOfLevels={3} colors={["#27AE61", "#F7943C", "#DF1616"]} arcWidth={0.5} hideText={true} style={chartStyle} arcPadding={0} cornerRadius={0} />
                </div> : null}
                <div className='flex flex-col items-center'>
                  <p className='font-display-regular text-[#363636] text-[0.75rem] tracking-wide'>My Data</p>
                  <p className={`font-display-medium ${obj.compare ? obj.name === 'NP Booking Window' ? obj.myData < 5 ? 'text-[#18B854]' : obj.myData >= 6 && obj.myData <= 7 ? 'text-[#F7943C]' : obj.myData > 7 ? 'text-[#DF1616]' : 'text-lite-black' : obj.name === 'No Show Rate YoY' || obj.name === 'Surgeries Without Prior Auth' ? obj.myData < 10 ? 'text-[#18B854]' : obj.myData >= 10 && obj.myData <= 15 ? 'text-[#F7943C]' : obj.myData > 15 ? 'text-[#DF1616]' : 'text-lite-black' : 'text-lite-black' : 'text-lite-black'}  text-base`}>{obj.myData}{obj.name === 'NP Booking Window' ? ' Days' : ''}{obj.name === 'No Show Rate YoY' || obj.name === 'Surgeries Without Prior Auth' ? '%' : ''}</p>
                </div>

                <div className='flex flex-col items-center'>
                  <p className='font-display-regular text-[#363636] text-[0.75rem] tracking-wide'>Region</p>
                  <p className='font-display-medium text-lite-black text-base'>{obj.region}{obj.name === 'No Show Rate YoY' || obj.name === 'Surgeries Without Prior Auth' ? '%' : ''}</p>
                </div>

                <div className='flex flex-col items-center'>
                  <p className='font-display-regular text-[#363636] text-[0.75rem] tracking-wide'>Platform</p>
                  <p className='font-display-medium text-lite-black text-base'>{obj.platform}{obj.name === 'No Show Rate YoY' || obj.name === 'Surgeries Without Prior Auth' ? '%' : ''}</p>
                </div>
              </div>

              {obj.total ?
                <>
                  <hr className='border border-[#98989866] mx-3 mb-4' />
                  <div className='flex justify-around mb-3 tracking-wide'>
                    <div className='flex flex-col items-center'>
                      <p className='font-display-regular text-lite-black text-[0.75rem]'>Total Amount</p>
                      <p className='font-display-regular text-lite-black text-[0.75rem]'>{obj.years}</p>
                      <p className='font-display-medium text-[#27AE61] text-base'>{obj.prevYear > obj.currYear ? Math.round([(obj.prevYear - obj.currYear) / obj.currYear] * 100) : Math.round([(obj.currYear - obj.prevYear) / obj.prevYear] * 100)}%</p>
                    </div>
                    {obj.compareData.map((data) => (
                      <div className='flex flex-col items-center'>
                        <p className='font-display-regular text-lite-black text-[0.75rem]'>Total Amount</p>
                        <p className='font-display-regular text-lite-black text-[0.75rem]'>{data.year}</p>
                        <p className='font-display-medium text-lite-black text-base'>${data.data}</p>
                      </div>
                    ))}
                  </div> </> : null}
              <div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Legend Pop-up Modal */}
      {legendModalOpen ?
        <div className={`fixed top-0 left-0 right-0 z-30 w-full h-screen ${legendModalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110 modal'}`}>
          <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
            <div>
              <div className='m-5 flex justify-end'>
                <img src={close} alt="close" onClick={() => setLegendModalOpen(false)} />
              </div>
              <div>
                <p className='text-[#1B453E] font-display-medium mb-5 text-center'>Legend</p>
                <hr className='border-1 border-[#98989866]  mt-4 mb-10 mx-[5.625rem]' />
                <div className='flex flex-col items-center gap-1.5 m-10'>
                  <p className='text-[#18B854] text-sm font-display-bold'>
                    Green
                  </p>
                  <p className='text-[0.75rem] font-display-regular text-lite-black'>{daysLegend ? '< 5 Days' : '<10%'}</p>
                </div>
                <div className='flex flex-col items-center gap-1.5 m-10'>
                  <p className='text-sm text-[#F7943C] font-display-bold'>
                    Amber
                  </p>
                  <p className='text-[0.75rem] text-lite-black font-display-regular'>{daysLegend ? '6 to 7 Days' : '10 to 15%'}</p>
                </div>
                <div className='flex flex-col items-center gap-1.5 m-10'>
                  <p className='text-sm text-[#DF1616] font-display-bold'>
                    Red
                  </p>
                  <p className='text-[0.75rem] text-lite-black font-display-regular'>{daysLegend ? '> 5 Days' : '>15%'}</p>
                </div>
              </div>
            </div>
          </div>
        </div> : null}
      {/* comparison Pop-up Modal */}
      {comparisonModalOpen ?
        <div className={`fixed top-0 left-0 right-0 z-30 w-full h-screen ${comparisonModalOpen ? 'visible scale-100 backdrop-blur-[20px] backdrop-brightness-75 show-modal opacity-100 ' : 'hidden opacity-0 scale-110 modal'}`}>
          <div className="absolute bottom-0 left-0 right-0 w-full bg-white rounded-t-[20px]">
            <div>
              <div className='m-5 flex justify-end'>
                <img src={close} alt="close" onClick={() => setComparisonModalOpen(false)} />
              </div>
              <div className='flex flex-col justify-center items-center font-display-medium px-4'>
                <div className='flex items-center gap-4 mb-5'>
                  <div>
                    <img src={calendar} alt="calendar" className='h-[1.875rem] w-[1.875rem]' />
                  </div>
                  <div className='flex flex-col font-display-medium text-sm'>
                    <p className='text-lite-black'>{period}</p>
                    <p className='text-[#888888]'>{month}</p>
                  </div>
                </div>
                <button className={`${period === 'Last 30 Days' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleLastMonthData}>Last 30 Days</button>
                <button className={`${period === 'Last 90 Days' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleLast3MonthsData}>Last 90 Days</button>
                <button className={`${period === 'Last 180 Days' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-2.5 w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleLast6MonthsData}>Last 180 Days</button>
                <button className={`${period === 'Last 365 Days' ? 'bg-[#8DC63F] text-[#1B453E] cursor-auto pointer-events-none' : 'bg-[#E9E9E9] text-[#888888]'} p-[1.375rem] rounded-lg mb-[3.125rem] w-full drop-shadow-[0_0_10px_#0000000D] text-sm`} onClick={handleLast12MonthsData}>Last 365 Days</button>
              </div>
            </div>
          </div>
        </div> : null}
    </div>
  )
};

export default OpenKPI
